import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify'
import { createDeviceBrand, getDeviceBrands, updateDeviceBrand, deleteDeviceBrand } from '../../api/deviceBrandApi';
import SingleDeviceBrand from './SingleDeviceBrand';
import DataListing from '../Common/DataListing';
import { Dialog } from 'primereact/dialog';


const DeviceBrandsList = () => {
    const [deviceBrands, setDeviceBrands] = useState([]);
    const [deviceBrandData, setDeviceBrandData] = useState({
        name: '',
        deviceType: null,
        
    });  //to be used for SingleDeviceBrand (Add/Edit)
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    
    const [columns, setColumns] = useState([
        { field: 'name', header: 'Brand Name' },
        { field: 'deviceType', header: 'Device Type' },

    ]);//to be used for DataListing

    useEffect(() => {
        fetchDeviceBrands();
    }, []);

    const fetchDeviceBrands = async () => {
        try {
            const response = await getDeviceBrands();
            // console.log("DeviceBrand", response)
            setDeviceBrands(response);
        } catch (error) {
            console.error('Failed to fetch deviceBrands', error);
        }
    };

    const responseAlert = (response, autoClose) => {
        if (response.success) {
            toast.success(response.msg, { autoClose: autoClose })
        }
        else {
            toast.error(response.msg, { autoClose: autoClose })
        }

    }

    const handleDelete = async (id) => {
        try {
            await deleteDeviceBrand(id);
            fetchDeviceBrands();
        } catch (error) {
            console.error('Failed to delete deviceBrands', error);
        }
    };

    const handleEdit = (rowData) => {
        setIsEdit(true);
        // console.log("deviceBrands rowData", rowData);
        setDeviceBrandData(rowData);
        setIsModalVisible(true);
    };

    const handleCreate = () => {
        setIsEdit(false);
        setDeviceBrandData({
            name: '',
            deviceType: '',
        });
        setIsModalVisible(true);
    };

    const handleSubmit = async () => {
        try {
            let response = {}
            if (isEdit) {
                response = await updateDeviceBrand(deviceBrandData.id, deviceBrandData);
            } else {
                response = await createDeviceBrand(deviceBrandData);
            }
            // console.log(response.data);
            responseAlert(response, 300);
            fetchDeviceBrands(); // Refresh the list after submission
            setIsModalVisible(false);
        } catch (error) {
            console.error('Error saving Device Brand', error);
        }
    };

    return (
        <>
            <DataListing
                data={deviceBrands}
                subject={"Device Brand"}
                columns={columns}
                onCreate={handleCreate}
                onEdit={handleEdit}
                onDelete={handleDelete} />
            <Dialog
                header="Device Brand"
                visible={isModalVisible}
                style={{ width: '30vw' }}
                onHide={() => setIsModalVisible(false)}>
                <SingleDeviceBrand
                    deviceBrand={deviceBrandData}
                    setDeviceBrand={setDeviceBrandData}
                    onSubmit={handleSubmit}
                    isEdit={isEdit}
                />
            </Dialog>
        </>
    );
};

export default DeviceBrandsList;
