// src/contexts/AbilityContext.js
import React, { createContext, useContext, useState, useEffect } from 'react';
import { AbilityBuilder, PureAbility } from '@casl/ability';

// Create a context for the ability instance
const AbilityContext = createContext(null);

export const useAbility = () => useContext(AbilityContext);

export const AbilityProvider = ({ children }) => {
    const [ability, setAbility] = useState(new PureAbility());

    useEffect(() => {
        const fetchUserAbilities = async () => {
            try {
                // Example API call to fetch user permissions
                const response = await fetch('/api/users/permissions');
                const data = await response.json();
                console.log("data",data);

                // Define abilities
                const { can, rules } = new AbilityBuilder(PureAbility);

                if (data.isAdmin) {
                    can('manage', 'all'); // Admin can manage everything
                } else {
                    data.permissions.forEach(permission => {
                        can(permission.action, permission.subject);
                    });
                }

                // Update the ability instance with new rules
                setAbility(new PureAbility(rules));
            } catch (error) {
                console.error('Failed to fetch user abilities:', error);
            }
        };

        fetchUserAbilities();
    }, []);

    return (
        <AbilityContext.Provider value={ability}>
            {children}
        </AbilityContext.Provider>
    );
};
