import axios from "axios";

const API_URL = "/api/goalactivities";
export const createGoalActivity = async (activity) => {
  const response = await axios.post(API_URL, activity);
  return response.data;
};
export const getAllGoalActivities = async () => {
  const response = await axios.get(API_URL);
  return response.data;
};
export const deleteGoalActivity = async (id) => {
  const response = await axios.delete(`${API_URL}/${id}`);
  return response.data;
};
export const updateGoalActivity = async (id, activity) => {
  const response = await axios.put(`${API_URL}/${id}`, activity);
  return response.data;
};
