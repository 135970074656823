// src/contexts/AuthContext.js
import React, { createContext, useContext, useState, useEffect } from 'react';
import { Navigate  } from 'react-router-dom';
import axios from 'axios';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [user, setUser] = useState(null);

    useEffect(() => {
        // Check if the user is authenticated when the component mounts
        axios.get('/api/auth/check')
            .then(response => {
                if (response.data.isAuthenticated) {
                    setIsAuthenticated(true);
                    setUser(response.data.user);
                }
            })
            .catch(error => {
                console.error('Error checking authentication', error);
            });
    }, []);

    const login = (credentials) => {
        return axios.post('/api/auth/login', credentials)
            .then(response => {
                console.log("Full response:", response.data);
                if (response.data.success) {
                    setIsAuthenticated(true);
                    console.log("current user",response.data.user);
                    setUser(response.data.user);
                    // console.log("auth user",response.data.user);
                }
                return response.data;
            });
    };

    const logout = () => {
        return axios.post('/api/auth/logout')
            .then(response => {
                console.log("logout res",response)
                setIsAuthenticated(false);
                setUser(null);
                // navigate('/login');
            });
    };

    const register = (userData) => {
        return axios.post('/api/auth/register', userData)
            .then(response => {
                if (response.data.success) {
                    setIsAuthenticated(true);
                    setUser(response.data.user);
                    // <Navigate to="/dashboard" />
                }
                return response.data;
            });
    };

    return (
        <AuthContext.Provider value={{ isAuthenticated, user, login, logout, register }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => useContext(AuthContext);
