// src/components/ProtectedRoute.js
import React from 'react';
import { useLocation,useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';

const ProtectedRoute = ({ element: Element, ...rest }) => {
  const { isAuthenticated } = useAuth();
  const navigate = useNavigate();

  if (!isAuthenticated) {
    console.log("unable to authenticate");
    // Redirect them to the login page if not logged in
    return navigate('/login');
  }

  // Render the component if authenticated
  return <Element />;
};

export default ProtectedRoute;
