import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import {
  createChild,
  getChildren,
  updateChild,
  deleteChild,
} from "../../api/childApi";
import { getServices } from "../../api/serviceApi";
import {
  fetchUserPermissions,
  getUserDirectPermissions,
  getUsers,
} from "../../api/userApi";
import SingleChild from "./SingleChild";
import DataListing from "../Common/DataListing";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { capitalizeFirstLetter, formatDate } from "../../utils/dateutils";
import {
  addChildSoapNote,
  deleteChildSoapNote,
  getChildSoapNoteById,
  updateChildSoapNote,
} from "../../api/childSoapNoteApi";
import { TabPanel, TabView } from "primereact/tabview";
import { useAuth } from "../../contexts/AuthContext";
import SingleChildSoapNote from "./SingleChildSoapNote";
import { hasPermission } from "../../utils/permissions";
import SingleWeeklyPlan from "./SingleWeeklyPlan";
import {
  addWeeklyPlan,
  deleteWeeklyPlan,
  editWeeklyPlan,
  getChildWeeklyPlans,
} from "../../api/weeklyPlanApi";
import { Tag } from "primereact/tag";
import { Divider } from "primereact/divider";
import { Card } from "primereact/card";
import { formatToCamelCase } from "../../utils/generalutils";
import { Accordion, AccordionTab } from "primereact/accordion";
import { Tooltip } from "primereact/tooltip";
import { ProgressSpinner } from "primereact/progressspinner";
import SingleGoal from "./SingleGoal";
import {
  createGoal,
  deleteGoal,
  getChildGoals,
  updateGoal,
} from "../../api/goalApi";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { ProgressBar } from "primereact/progressbar";

const ChildrenList = () => {
  const { user } = useAuth();
  const [children, setChildren] = useState([]);
  const [childData, setChildData] = useState({
    fullName: "",
    dateOfBirth: null,
    gender: "",
    parentId: null,
    diagnosis: "",
    fatherName: "",
    fatherContact: "",
    motherName: "",
    motherContact: "",
    fatherOccupation: "",
    motherOccupation: "",
    address: "",
    familySystem: "",
    siblings: 0,
    birthOrder: "",
    birthHistory: "",
    currentMedications: "",
    allergies: "",
    mainConcerns: "",
    recommendation: "",
    hearingAge: "",
    dateOfHLDiagnosis: null,
    causeOfHL: "",
    hlRightEar: "",
    hlLeftEar: "",
    dateOfHAUse: null,
    isHLProgressive: "",
    haBrandRightEarId: null,
    haBrandLeftEarId: null,
    haModelRightEarId: null,
    haModelLeftEarId: null,
    dateOfCIRightEar: null,
    dateOfCILeftEar: null,
    CIBrandId: null,
    CIModelId: null,
    chosenModeOfCommunication: "",
    nativeLanguage: "",
    isGoingSchool: false,
    grade: "",
    languageSpokenInSchool: "",
  }); //to be used for SingleChild (Add/Edit)
  const [childId, setChildId] = useState(null);
  const [childName, setChildName] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isChildTherapistsModalVisible, setIsChildTherapistsModalVisible] =
    useState(false);
  const [allTherapists, setAllTherapists] = useState([]);
  const [services, setServices] = useState([]);
  const [selectedChildTherapists, setselectedChildTherapists] = useState([]);
  const [notesDialogOpen, setNotesDialogOpen] = useState(false);
  const [notes, setNotes] = useState([]);
  const [permissions, setPermissions] = useState([]);
  const [weeklyPlansDialogVisible, setWeeklyPlansDialogVisible] =
    useState(false);
  const [showAddEditWeeklyPlanModal, setShowAddEditWeeklyPlanModal] =
    useState(false);
  const [showAddEditSoapModal, setShowAddEditSoapModal] = useState(false);
  const [isGoalModalVisible, setIsGoalModalVisible] = useState(false);
  const [isSingleGoalModalVisible, setIsSingleGoalModalVisible] =
    useState(false);
  const [goals, setGoals] = useState([]);
  const [goal, setGoal] = useState({
    name: "",
    description: "",
    startDate: Date.now(),
    childId: null,
    goalDomainId: null,
    goalProgress: 0,
    // activityDate: null,
    // goalActivityId: null,
  });
  const [goalDetails, setGoalDetails] = useState([
    {
      activityDate: null,
      goalActivityId: null,
      // goalProgress: 0,
    },
  ]);
  const [goalEditMode, setGoalEditMode] = useState(false);

  const [newNote, setNewNote] = useState({
    subjective: "",
    objective: "",
    noteDate: null,
    assessment: "",
    plan: "",
    note: "",
    serviceId: null,
    therapistId: user.id,
  });
  const [editMode, setEditMode] = useState(false); // State to manage edit mode
  const [loading, setLoading] = useState(false);
  const [weeklyPlan, setWeeklyPlan] = useState({
    planMonth: "",
    planWeek: "",
    childId: null,
    therapistId: null,
    planDetails: "",
    planStatus: "pending",
  });
  const [allWeeklyPlans, setAllWeeklyPlans] = useState([]);
  const [planEditMode, setPlanEditMode] = useState(false);
  const [editingWeeklyPlanStatus, SetEditingWeeklyPlanStatus] = useState(false);
  const statusOptions = [
    { label: "Pending", value: "pending" },
    { label: "Approved", value: "approved" },
    { label: "Rejected", value: "rejected" },
  ];

  useEffect(() => {
    const getPermissions = async () => {
      setLoading(true);
      try {
        const userPermissions = await fetchUserPermissions(user.id);
        // console.log("userPermissions", userPermissions);
        setPermissions(userPermissions);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching permissions:", error);
        setLoading(false);
      }
    };
    getPermissions();
  }, []);

  const fetchChildSOAP = async () => {
    if (childId) {
      setLoading(true);
      try {
        const response = await getChildSoapNoteById(childId);
        const sortedNotes = response.sort(
          (a, b) => new Date(b.noteDate) - new Date(a.noteDate)
        );
        setNotes(sortedNotes);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching SOAP notes:", error);
        setLoading(false);
      }
    }
  };

  const handleOpenNotesDialog = async (rowData) => {
    setChildId(rowData.id);
    setChildName(rowData.fullName);
    try {
      setLoading(true);
      const response = await getChildSoapNoteById(rowData.id);
      const sortedNotes = response.sort(
        (a, b) => new Date(b.noteDate) - new Date(a.noteDate)
      );
      setNotes(sortedNotes);
      setLoading(false);
    } catch (error) {
      console.log(error);
      toast.error("Error fetching SOAP notes");
      setLoading(false);
    }
    setNotesDialogOpen(true);
  };
  // Close the dialog
  const handleCloseNotesDialog = () => {
    setNotesDialogOpen(false);
  };
  const handleSaveNote = async () => {
    const payload = {
      childId: childId,
      ...newNote,
    };
    setLoading(true);
    try {
      let response = {};
      if (editMode) {
        response = await updateChildSoapNote(newNote.id, payload);
      } else {
        response = await addChildSoapNote(payload);
      }
      if (response) {
        setEditMode(false);
        setNewNote({});
        await fetchChildSOAP();
        setShowAddEditSoapModal(false);
        responseAlert(response, 300);
        setLoading(false);
      }
    } catch (error) {
      console.error(error);
      setLoading(false);
    }

    // handleCloseNotesDialog();
  };
  const handleAddNote = () => {
    setEditMode(false);
    setShowAddEditSoapModal(true);
  };
  const handleEditNote = (note) => {
    setNewNote(note); // Populate the form with the selected note data
    setEditMode(true); // Set edit mode to true
    setShowAddEditSoapModal(true);
  };
  const handleDeleteNote = async (id) => {
    const isConfirmed = window.confirm(
      "Are you sure you want to delete this SOAP note?"
    );
    if (isConfirmed) {
      setLoading(true);
      try {
        const response = await deleteChildSoapNote(id);
        //   await axios.post("/api/childsoapnote", payload);
        setNewNote({
          subjective: "",
          objective: "",
          assessment: "",
          plan: "",
          noteDate: "",
          note: "",
        });
        await fetchChildSOAP();
        // handleCloseNotesDialog();
        toast.success("Child SOAP Note Deleted Successfully");
        setLoading(false);
      } catch (error) {
        console.error(error);
        toast.error("Error In Deleting Child SOAP Note ");
        setLoading(false);
      }
    } else {
      return;
    }
  };
  const groupedNotes = notes.reduce((acc, note) => {
    const serviceName = note.service?.name;
    if (!acc[serviceName]) {
      acc[serviceName] = [];
    }
    acc[serviceName].push(note);
    return acc;
  }, {});
  const handleSaveWeeklyPlan = async () => {
    setLoading(true);
    try {
      let response = {};
      if (planEditMode === true) {
        response = await editWeeklyPlan(weeklyPlan.id, weeklyPlan);
      } else {
        response = await addWeeklyPlan(weeklyPlan);
      }
      if (response) {
        await fetchWeeklyPlans(childId);
        handleCloseEditPlanDialog();
        responseAlert(response, 1000);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };
  const handleDeleteWeeklyPlan = async (id) => {
    const confirm = window.confirm(
      "Are you sure you want to delete this plan?"
    );

    if (!confirm) return;

    try {
      setLoading(true); // Optional: use loading for better UX
      const response = await deleteWeeklyPlan(id);

      if (response.success) {
        await fetchWeeklyPlans();
        toast.success("Weekly Plan Deleted Successfully", { autoClose: 2000 });
      } else {
        toast.error(response.message || "Failed to delete weekly plan"); // Show error from response if available
      }
    } catch (e) {
      console.error(e);
      toast.error("Error in deleting weekly plan");
    } finally {
      setLoading(false); // Ensure loading is reset even if there's an error
    }
  };

  // console.log("selectedChildTherapists",selectedChildTherapists);
  const renderParentName = (rowData) => {
    return <span>{rowData.parent.name}</span>;
  };

  const renderDate = (fieldName) => {
    return (rowData) => {
      return <span>{formatDate(rowData[fieldName])}</span>;
    };
  };

  // const actionBody = (rowData) => {
  //   return (
  //     <div className="actions">
  //       <Button
  //         // icon="pi pi-plus"
  //         className="btn-outline-secondary mr-2"
  //         onClick={() => handleOpenNotesDialog(rowData)}
  //         label="SOAP Notes"
  //       />
  //     </div>
  //   );
  // };
  const fetchWeeklyPlans = async (id) => {
    setLoading(true);
    try {
      const response = await getChildWeeklyPlans(id);
      // console.log(response);
      if (user.roleId <= 2) {
        setAllWeeklyPlans(response);
      } else {
        setAllWeeklyPlans(
          response.filter((item) => item.therapistId === user.id)
        );
      }
      // console.log(allWeeklyPlans);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };
  const handleOpenWeeklyPlansDialog = async (rowData) => {
    setChildId(rowData.id);
    setChildName(rowData.fullName);
    setWeeklyPlan((prev) => ({
      ...prev,
      childId: rowData.id,
    }));

    if (user.roleId === 4) {
      setWeeklyPlan((prev) => ({
        ...prev,
        therapistId: user.id,
      }));
    }

    setLoading(true); // Optional: shows loading while fetching data
    try {
      await fetchWeeklyPlans(rowData.id);
      setWeeklyPlansDialogVisible(true);
    } catch (error) {
      console.error("Error fetching weekly plans:", error);
    } finally {
      setLoading(false); // Ensure loading is reset
    }
  };
  const handleCloseWeeklyPlansDialog = () => {
    setWeeklyPlansDialogVisible(false);
    setWeeklyPlan({
      planMonth: "",
      planWeek: "",
      childId: null,
      therapistId: null,
      planDetails: "",
    });
  };

  const handleAddWeeklyPlan = () => {
    setShowAddEditWeeklyPlanModal(true);
    setPlanEditMode(false);
  };
  const handleEditWeeklyPlan = (plan) => {
    setWeeklyPlan(plan);
    setShowAddEditWeeklyPlanModal(true);
    setPlanEditMode(true);
  };
  const handleWeeklyPlanStatusChange = async (planId, newStatus) => {
    setLoading(true);
    try {
      await editWeeklyPlan(planId, { planStatus: newStatus });
      setWeeklyPlan((prevWeeklyPlan) => ({
        ...prevWeeklyPlan,
        planStatus: newStatus,
      }));
    } catch (error) {
      console.error("Error updating status:", error);
      SetEditingWeeklyPlanStatus(false);
    } finally {
      setLoading(false);
    }
  };
  const handleCloseEditPlanDialog = () => {
    setShowAddEditWeeklyPlanModal(false);
    setPlanEditMode(false);
    setWeeklyPlan({
      planMonth: "",
      planWeek: "",
      // childId: null,
      // therapistId: null,
      planDetails: "",
    });
  };
  const groupPlansByTherapistAndMonth = (plans) => {
    return plans.reduce((acc, plan) => {
      const therapistName = plan.therapist?.name || "Unknown Therapist";
      const planMonth = plan.planMonth || "Unknown Month";

      if (!acc[therapistName]) {
        acc[therapistName] = {};
      }

      if (!acc[therapistName][planMonth]) {
        acc[therapistName][planMonth] = [];
      }

      acc[therapistName][planMonth].push(plan);
      return acc;
    }, {});
  };

  const plansGroupedByTherapistAndMonth =
    groupPlansByTherapistAndMonth(allWeeklyPlans);

  const [columns, setColumns] = useState([
    { field: "fullName", header: "Child Name" },
    { header: "Date of Birth", body: renderDate("dateOfBirth") },
    { field: "hearingAge", header: "Hearing Age" },
    { header: "Parent", body: renderParentName },
    // { header: "User Actions", body: actionBody },
  ]); //to be used for DataListing

  useEffect(() => {
    fetchChildren();
    fetchServices();
    fetchTherapists();
    // console.log(selectedChildTherapists.length);
  }, []);
  // const dropdownServices = [
  //   { label: "Select Service", value: null },
  //   ...services.map((service) => ({
  //     label: service.name,
  //     value: service.id,
  //   })),
  // ];
  const fetchChildren = async () => {
    setLoading(true);
    try {
      const response = await getChildren();
      // console.log("Child Data", response);
      setChildren(response);
    } catch (error) {
      console.error("Failed to fetch children", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchServices = async () => {
    setLoading(true);
    try {
      const response = await getServices();
      setServices(response);
    } catch (error) {
      console.error("Error fetching services", error);
    } finally {
      setLoading(false);
    }
  };
  const fetchTherapists = async () => {
    setLoading(true);
    try {
      const users = await getUsers();
      const therapists = users.filter((user) => user.roleId === 4);
      setAllTherapists(therapists);
      // console.log("users",users);
      // console.log("allTherapists",allTherapists);
    } catch (error) {
      console.error("Error fetching services", error);
    } finally {
      setLoading(false);
    }
  };

  const responseAlert = (response, autoClose) => {
    if (response.success) {
      toast.success(response.msg, { autoClose: autoClose });
    } else {
      toast.error(response.msg, { autoClose: autoClose });
    }
  };

  const handleDelete = async (id) => {
    setLoading(true);
    try {
      await deleteChild(id);
      fetchChildren();
    } catch (error) {
      console.error("Failed to delete children", error);
    } finally {
      setLoading(false);
    }
  };

  const handleEdit = (rowData) => {
    setIsEdit(true);
    // console.log("children rowData", rowData);
    setChildData(rowData);
    setIsModalVisible(true);
  };

  const handleCreate = () => {
    setIsEdit(false);
    // setChildData({
    //     name: '',
    //     age: '',
    // });
    setIsModalVisible(true);
    if (user.roleId === 3) {
      setChildData((prev) => ({
        ...prev,
        parentId: user.id,
      }));
    }
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      let response = {};
      if (isEdit) {
        response = await updateChild(childData.id, childData);
      } else {
        console.log(childData);
        response = await createChild(childData);
      }
      if (response.success) {
        responseAlert(response, 500);
        fetchChildren();
        setIsModalVisible(false);
        setIsEdit(false);
        setChildData({});
      } else {
        responseAlert(response, 3000);
      }
    } catch (error) {
      console.error("Error saving Child Data", error);
    } finally {
      setLoading(false);
    }
  };

  const addTherapistRecord = () => {
    setselectedChildTherapists([
      ...selectedChildTherapists,
      { service: null, therapist: null },
    ]);
  };
  const removeServiceRow = (index) => {
    if (selectedChildTherapists.length > 1) {
      const updatedChildTherapists = [...selectedChildTherapists];
      updatedChildTherapists.splice(index, 1);
      setselectedChildTherapists(updatedChildTherapists);
      setChildData((prevData) => ({
        ...prevData,
        childTherapists: updatedChildTherapists,
      }));
    }
  };

  const handleServiceChange = (serviceId, index) => {
    const updatedChildTherapists = [...selectedChildTherapists];
    const service = services.find((service) => service.id === serviceId);
    updatedChildTherapists[index] = {
      ...updatedChildTherapists[index],
      service,
    };
    // console.log("on change service",service);
    setselectedChildTherapists(updatedChildTherapists);
    setChildData((prevData) => ({
      ...prevData,
      childTherapists: updatedChildTherapists,
    }));
  };
  const handleTherapistChange = (therapistId, index) => {
    const updatedChildTherapists = [...selectedChildTherapists];
    const therapist = allTherapists.find(
      (therapist) => therapist.id === therapistId
    );
    updatedChildTherapists[index] = {
      ...updatedChildTherapists[index],
      therapist,
    };
    setselectedChildTherapists(updatedChildTherapists);
    setChildData((prevData) => ({
      ...prevData,
      childTherapists: updatedChildTherapists,
    }));
  };

  const updateChildTherapists = async () => {
    setLoading(true);
    try {
      let response = await updateChild(childData.id, childData); // Update the user
      responseAlert(response, 300);
      setIsChildTherapistsModalVisible(false);
      await fetchChildren();
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleChildTherapists = async (rowData) => {
    setChildData(rowData);
    setselectedChildTherapists(rowData.childTherapists);
    if (rowData.childTherapists.length === 0) {
      addTherapistRecord();
    }
    setIsChildTherapistsModalVisible(true);
  };
  const fetchChildGoals = async (childId) => {
    setLoading(true);
    try {
      const response = await getChildGoals(childId);
      setGoals(response);
      console.log(goals);

      setLoading(false);
    } catch (error) {
      console.error("Error fetching goals:", error);
      setLoading(false);
    }
  };
  const groupedGoals =
    goals.length >= 1 &&
    goals.reduce((acc, goal) => {
      const domainName = goal.GoalDomain?.name;
      if (!acc[domainName]) {
        acc[domainName] = [];
      }
      acc[domainName].push(goal);
      return acc;
    }, {});
  const handleOpenGoalModal = async (rowData) => {
    setChildId(rowData.id);
    setGoal((prev) => ({
      ...prev,
      childId: rowData.id,
    }));
    console.log(goal);

    await fetchChildGoals(rowData.id);
    setChildName(rowData.fullName);
    setIsGoalModalVisible(true);
  };
  const handleOpenSingleGoalModal = async () => {
    setIsSingleGoalModalVisible(true);
  };
  const handleEditGoal = (selectedGoal) => {
    console.log(
      "Detail Id",
      selectedGoal.GoalDetails.map((detail) => detail.id)
    );

    setGoal({
      id: selectedGoal.id,
      name: selectedGoal.name,
      description: selectedGoal.description,
      startDate: new Date(selectedGoal.startDate),
      childId: selectedGoal.childId,
      goalDomainId: selectedGoal.goalDomainId,
      goalProgress: selectedGoal.goalProgress,
    });

    setGoalDetails(
      selectedGoal.GoalDetails.map((detail) => ({
        goalDetailId: detail.id,
        activityDate: new Date(detail.activityDate),
        goalActivityId: detail.goalActivityId,
        // goalProgress: detail.goalProgress,
      }))
    );

    setGoalEditMode(true);
    setIsSingleGoalModalVisible(true);
  };
  const handledeleteGoal = async (goalId) => {
    setLoading(true);
    try {
      const response = await deleteGoal(goalId);
      responseAlert(response, 500);
      await fetchChildGoals(childId);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };
  const handleSaveGoal = async () => {
    setLoading(true);
    // setGoal({ ...goal, childId: childId });
    try {
      console.log("goal", goal);
      console.log("goalDetails", goalDetails);

      let response = {};
      if (goalEditMode) {
        response = await updateGoal(goal.id, goal, goalDetails);
      } else {
        response = await createGoal(goal, goalDetails);
      }
      responseAlert(response, 500);
      if (response.success) {
        // setIsGoalModalVisible(false);
        setIsSingleGoalModalVisible(false);
        setGoalEditMode(false);
        setGoal((prev) => ({
          ...prev,
          id: null,
          name: "",
          description: "",
          startDate: Date.now(),
          goalDomainId: null,
          goalProgress: 0,
        }));
        setGoalDetails([
          {
            activityDate: null,
            goalActivityId: null,
            // goalProgress: 0,
          },
        ]);
        await fetchChildGoals(childId);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };
  const allExtraActions = [
    {
      label: "Child Therapists",
      className: "btn-outline-primary",
      handler: handleChildTherapists,
      condition: (rowData) => {
        return user.roleId <= 2;
      },
    },
    hasPermission(permissions, "Goal", "read") && {
      label: "Child Goals",
      className: "btn-outline-primary",
      handler: (rowData) => handleOpenGoalModal(rowData),
    },
    hasPermission(permissions, "Soap Note", "read") && {
      label: "Child Soap Notes",
      className: "btn-outline-primary",
      handler: (rowData) => handleOpenNotesDialog(rowData),
    },
    hasPermission(permissions, "Weekly Plan", "read") && {
      label: "Weekly Plans",
      className: "btn-outline-primary",
      handler: (rowData) => handleOpenWeeklyPlansDialog(rowData),
      // condition: (rowData) => user.roleId <= 2,
    },
  ].filter(Boolean);
  // const childTherapistsAction = [
  //   {
  //     label: "Child Therapists",
  //     className: "btn-outline-primary",
  //     handler: handleChildTherapists,
  //     condition: (rowData) => {
  //       return user.roleId <= 2;
  //     },
  //   },
  // ];
  // const soapNotesAction = [
  //   {
  //     label: "Child Soap Notes",
  //     className: "btn-outline-primary",
  //     handler: (rowData) => handleOpenNotesDialog(rowData),
  //     condition: (rowData) => {
  //       return user.roleId <= 2;
  //     },
  //   },
  // ];

  return (
    <>
      <DataListing
        data={children}
        subject={"Child"}
        columns={columns}
        onCreate={handleCreate}
        onEdit={handleEdit}
        onDelete={handleDelete}
        extraActions={allExtraActions}
      />
      <Dialog
        header="Child Details"
        visible={isModalVisible}
        style={{ width: "50vw" }}
        onHide={() => {
          setIsModalVisible(false);
          setIsEdit(false);
          setChildData({});
        }}
      >
        <SingleChild
          showParents={user.roleId <= 2 ? true : false}
          child={childData}
          setChild={setChildData}
          onSubmit={handleSubmit}
          isEdit={isEdit}
          loading={loading}
          setLoading={setLoading}
        />
      </Dialog>
      <Dialog
        header={`SOAP Notes for ${childName} `}
        headerStyle={{ textAlign: "center" }}
        visible={notesDialogOpen}
        onHide={handleCloseNotesDialog}
        style={{ width: "50vw" }}
      >
        <>
          {hasPermission(permissions, "Soap Note", "create") && (
            <Button
              label="Add New Note"
              icon="pi pi-plus"
              className="btn-primary"
              onClick={handleAddNote}
            />
          )}
          <div className="p-grid p-fluid">
            {notes.length >= 1 ? (
              <TabView>
                {Object.keys(groupedNotes).map((serviceName) => (
                  <TabPanel key={serviceName} header={serviceName}>
                    {groupedNotes[serviceName].map((note) => (
                      <div key={note.id} className="p-shadow-2 p-p-3 p-mb-3">
                        <h4 className="p-text-primary p-mb-2">
                          <i className="pi pi-clipboard p-mr-2"></i>
                          {note.service?.name}
                        </h4>
                        <div className="p-mb-2">
                          <p className="border-1 px-2 py-2">
                            <strong className="p-text-secondary">Date:</strong>{" "}
                            {note?.noteDate !== null
                              ? formatDate(note?.noteDate)
                              : formatDate(note.createdAt)}
                            {/* {formatDate(note.createdAt)} */}
                          </p>
                          <p className="border-1 px-2 py-2">
                            <strong className="p-text-secondary">
                              Subjective:
                            </strong>{" "}
                            {note.subjective}
                          </p>
                          <p className="border-1 px-2 py-2">
                            <strong className="p-text-secondary">
                              Objective:
                            </strong>{" "}
                            {note.objective}
                          </p>
                          <p className="border-1 px-2 py-2">
                            <strong className="p-text-secondary">
                              Assessment:
                            </strong>{" "}
                            {note.assessment}
                          </p>
                          <p className="border-1 px-2 py-2">
                            <strong className="p-text-secondary">Plan:</strong>{" "}
                            {note.plan}
                          </p>
                          <p className="border-1 px-2 py-2">
                            <strong className="p-text-secondary">Note:</strong>{" "}
                            {note.note}
                          </p>
                        </div>
                        {hasPermission(permissions, "Soap Note", "update") && (
                          <div className="p-d-flex p-jc-between p-ai-center float-end ">
                            {hasPermission(
                              permissions,
                              "Soap Note",
                              "update"
                            ) && (
                              <Button
                                icon="pi pi-pencil"
                                className="p-button-rounded p-button-text p-button-info"
                                aria-label="Edit"
                                tooltip="Edit Note"
                                tooltipOptions={{ position: "bottom" }}
                                onClick={() => handleEditNote(note)}
                              />
                            )}
                            {hasPermission(
                              permissions,
                              "Soap Note",
                              "delete"
                            ) && (
                              <Button
                                icon="pi pi-trash"
                                className="p-button-rounded p-button-text p-button-danger"
                                aria-label="Delete"
                                tooltip="Delete Note"
                                onClick={() => handleDeleteNote(note.id)}
                              />
                            )}
                          </div>
                        )}
                      </div>
                    ))}
                  </TabPanel>
                ))}
              </TabView>
            ) : (
              <div className="text-center">No SOAP Notes Found! </div>
            )}
            <Dialog
              visible={showAddEditSoapModal}
              onHide={() => {
                setNewNote("");
                setShowAddEditSoapModal(false);
              }}
              header={editMode ? "Edit SOAP Note" : "Add SOAP Note"}
              headerStyle={{ textAlign: "center" }}
              style={{ width: "50vw" }}
            >
              <div className="p-grid p-fluid">
                <SingleChildSoapNote
                  newNote={newNote}
                  setNewNote={setNewNote}
                  services={services}
                  allTherapists={allTherapists}
                  handleSaveNote={handleSaveNote}
                  editMode={editMode}
                  showTherapists={
                    user.roleId === 1 || user.roleId === 2 ? true : false
                  }
                />
              </div>
            </Dialog>
          </div>
        </>
      </Dialog>
      <Dialog
        header="Child Therapists"
        visible={isChildTherapistsModalVisible}
        style={{ width: "30vw" }}
        onHide={() => setIsChildTherapistsModalVisible(false)}
      >
        <div className="form-group">
          <div className="p-field">
            {selectedChildTherapists.map((therapistEntry, index) => (
              <div key={index} className="grid p-align-center">
                {/*  full-width */}
                <div className="col-6">
                  <label style={{ display: "block", marginBottom: "0.5rem" }}>
                    Service
                  </label>
                  <Dropdown
                    value={therapistEntry.service?.id}
                    options={services}
                    onChange={(e) => handleServiceChange(e.value, index)}
                    optionLabel="name"
                    optionValue="id"
                    placeholder="Select a Service"
                    className="full-width"
                  />
                </div>
                <div className="col-6 ">
                  <label style={{ display: "block", marginBottom: "0.5rem" }}>
                    Therapist
                  </label>
                  <Dropdown
                    value={therapistEntry.therapist?.id}
                    options={allTherapists.length ? allTherapists : []}
                    onChange={(e) => handleTherapistChange(e.value, index)}
                    optionLabel="name"
                    optionValue="id"
                    placeholder="Select a Therapist"
                    className="full-width"
                  />
                </div>
                <div
                  className="col-12 p-d-flex p-jc-center p-ai-center"
                  style={{ float: "right" }}
                >
                  <Button
                    icon="pi pi-plus"
                    className="p-button-sm p-button-rounded p-button-success"
                    onClick={addTherapistRecord}
                    style={{ marginRight: "5px" }}
                  />
                  <Button
                    icon="pi pi-minus"
                    className="p-button-sm p-button-rounded p-button-danger"
                    onClick={() => removeServiceRow(index)}
                  />
                </div>
              </div>
            ))}
          </div>
          <div className="mt-2">
            <Button
              label="Submit"
              className="full-width btn-primary"
              icon="pi pi-check"
              onClick={() => updateChildTherapists()}
            />
          </div>
        </div>
      </Dialog>
      <Dialog
        visible={weeklyPlansDialogVisible}
        onHide={handleCloseWeeklyPlansDialog}
        header={`Weekly Plans for ${childName}`}
        headerStyle={{ textAlign: "center" }}
        modal
        style={{ width: "50vw" }}
      >
        {hasPermission(permissions, "Weekly Plan", "create") && (
          <Button
            label="Add New"
            icon="pi pi-plus"
            style={{ marginRight: "0.2rem" }}
            className="btn-primary"
            onClick={handleAddWeeklyPlan}
          />
        )}
        <div>
          {allWeeklyPlans.length >= 1 ? (
            <TabView>
              {Object.keys(plansGroupedByTherapistAndMonth).map(
                (therapistName) => (
                  <TabPanel header={therapistName} key={therapistName}>
                    <Accordion multiple>
                      {Object.keys(
                        plansGroupedByTherapistAndMonth[therapistName]
                      ).map((planMonth) => (
                        <AccordionTab key={planMonth} header={planMonth}>
                          {plansGroupedByTherapistAndMonth[therapistName][
                            planMonth
                          ].map((plan) => (
                            <div
                              key={plan.id}
                              className="p-col-12 p-md-6 p-lg-4"
                            >
                              <Card
                                title={plan.planWeek}
                                className="p-mb-4 my-2"
                              >
                                <div className="p-d-flex p-flex-column p-jc-between">
                                  <div>
                                    <div className="border-1 p-2">
                                      <strong className="underline">
                                        Details:
                                      </strong>
                                      <p>{plan.planDetails}</p>
                                    </div>

                                    <div className="border-1 p-2 mt-3">
                                      <strong className=" underline">
                                        Status:
                                      </strong>

                                      {editingWeeklyPlanStatus === false ? (
                                        <span
                                          className="mx-2 cursor-pointer"
                                          id={`tag-${plan.id}`}
                                        >
                                          <Tag
                                            value={formatToCamelCase(
                                              plan.planStatus
                                            )}
                                            severity={
                                              plan.planStatus === "approved"
                                                ? "success"
                                                : plan.planStatus === "rejected"
                                                ? "danger"
                                                : "warning"
                                            }
                                            onClick={() =>
                                              SetEditingWeeklyPlanStatus(true)
                                            }
                                          />
                                          {user.roleId <= 2 && (
                                            <Tooltip
                                              target={`#tag-${plan.id}`}
                                              content="Click To Edit"
                                              position="top"
                                              style={{ fontSize: "12px" }}
                                            />
                                          )}
                                        </span>
                                      ) : (
                                        <Dropdown
                                          value={plan.planStatus} // Current status
                                          options={statusOptions} // Status options
                                          onChange={async (e) => {
                                            await handleWeeklyPlanStatusChange(
                                              plan.id,
                                              e.value
                                            );
                                            plan.planStatus = e.value;
                                            SetEditingWeeklyPlanStatus(false);
                                          }} // Handle status change
                                          placeholder="Select a Status"
                                          className="mx-2"
                                        />
                                      )}
                                    </div>
                                  </div>

                                  <div className="mt-3">
                                    {hasPermission(
                                      permissions,
                                      "Weekly Plan",
                                      "update"
                                    ) && (
                                      <Button
                                        label="Edit"
                                        icon="pi pi-pencil"
                                        className="p-mt-2 mx btn-primary"
                                        onClick={() =>
                                          handleEditWeeklyPlan(plan)
                                        }
                                      />
                                    )}
                                    {hasPermission(
                                      permissions,
                                      "Weekly Plan",
                                      "delete"
                                    ) && (
                                      <Button
                                        label="Delete"
                                        icon="pi pi-trash"
                                        className="p-mt-2 mx-2 btn-secondary"
                                        // className="p-button-outlined p-mt-2 mx-2"
                                        onClick={() =>
                                          handleDeleteWeeklyPlan(plan.id)
                                        }
                                      />
                                    )}
                                  </div>
                                  {/* )} */}
                                </div>
                              </Card>
                            </div>
                          ))}
                        </AccordionTab>
                      ))}
                    </Accordion>
                  </TabPanel>
                )
              )}
            </TabView>
          ) : (
            <div className="text-center mt-2">No Weekly Plan Available! </div>
          )}
        </div>
        <Dialog
          header={planEditMode ? "Edit Weekly Plan" : "Add Weekly Plan"}
          headerStyle={{ textAlign: "center" }}
          visible={showAddEditWeeklyPlanModal}
          style={{ width: "50vw" }}
          onHide={handleCloseEditPlanDialog}
          modal
        >
          <SingleWeeklyPlan
            childId={childId}
            showTherapists={user.roleId <= 2 ? true : false}
            allTherapists={allTherapists}
            weeklyPlan={weeklyPlan}
            setWeeklyPlan={setWeeklyPlan}
            handleSaveWeeklyPlan={handleSaveWeeklyPlan}
            planEditMode={planEditMode}
          />
        </Dialog>
      </Dialog>
      <Dialog
        visible={isGoalModalVisible}
        headerStyle={{ textAlign: "center" }}
        style={{ width: "50vw", minWidth: "400px", maxWidth: "90%" }}
        onHide={() => {
          setIsGoalModalVisible(false);
        }}
        header={`Goals For ${childName}`}
        modal
      >
        <div>
          {hasPermission(permissions, "Goal", "create") && (
            <Button
              className="btn btn-primary"
              icon="pi pi-plus"
              onClick={handleOpenSingleGoalModal}
              label="Add New Goal"
            />
          )}

          <div className="d-grid p-fluid mt-3">
            {goals.length >= 1 ? (
              <TabView defaultValue={0}>
                {["pending", "in-progress", "completed"].map((status) => {
                  const filteredGoals = goals.filter(
                    (goal) => goal.status === status
                  );

                  return (
                    filteredGoals.length >= 1 && (
                      <TabPanel
                        key={status}
                        header={capitalizeFirstLetter(status)}
                      >
                        <Accordion multiple>
                          {filteredGoals.map((goal) => (
                            <AccordionTab
                              key={goal.id}
                              header={`${goal.name} - ${goal.GoalDomain.name}`}
                            >
                              <div className="p-3 surface-card">
                                <div className="gap-3">
                                  <div className="flex flex-row gap-2">
                                    <div className="w-7">
                                      <strong>Description: </strong>
                                      <div
                                        className="col text-overflow-ellipsis mt-1"
                                        style={{
                                          fontSize: "16px",
                                          color: "#555",
                                          placeContent: "center",
                                          textAlign: "justify", // Center alignment
                                          overflowY: "auto", // Enable vertical scrolling
                                          height: "125px", // Fixed height
                                          lineHeight: "23px", // Set line height
                                          wordBreak: "break-word", // Break long words
                                          border: "1px solid #ccc",
                                          borderRadius: "15px",
                                        }}
                                      >
                                        {goal.description}
                                      </div>
                                    </div>
                                    <div className="flex align-items-center justify-content-center gap-2 w-5 flex-column">
                                      <div className="flex flex-column justify-content-center align-items-center">
                                        <strong className="mx-3">
                                          Start Date:{" "}
                                        </strong>
                                        <div className="flex flex-row justify-content-center align-items-center my-2">
                                          <i className="pi pi-calendar text-secondary mx-2" />
                                          <small className="text-secondary my-2">
                                            {formatDate(goal.startDate)}
                                          </small>
                                        </div>
                                      </div>
                                      <div className="flex flex-column justify-content-center align-items-center mx-2">
                                        <strong className="mx-3">
                                          Progress:{" "}
                                        </strong>
                                        <div className="flex flex-row justify-content-center align-items-center">
                                          <ProgressBar
                                            value={goal.goalProgress || 0}
                                            showValue={false}
                                            style={{
                                              height: "10px",
                                              width: "200px",
                                              borderRadius: "15px",
                                              backgroundColor: "#e9ecef",
                                            }}
                                            className="my-2"
                                          />
                                        </div>
                                        <span>{goal.goalProgress || 0}%</span>
                                      </div>
                                    </div>
                                  </div>

                                  <DataTable
                                    value={goal.GoalDetails}
                                    className="p-datatable-sm mt-3 shadow-2"
                                    responsiveLayout="scroll"
                                  >
                                    <Column
                                      field="GoalActivity.name"
                                      header="Activity"
                                    />
                                    <Column
                                      field="activityDate"
                                      header="Activity Date"
                                      body={(row) => (
                                        <p>{formatDate(row.activityDate)}</p>
                                      )}
                                    />
                                  </DataTable>

                                  <div className="flex justify-content-end gap-2 mt-5">
                                    {hasPermission(
                                      permissions,
                                      "Goal",
                                      "update"
                                    ) && (
                                      <Button
                                        icon="pi pi-pencil"
                                        className="p-button-rounded p-button-outlined"
                                        style={{ color: "#655BC6" }}
                                        onClick={() => handleEditGoal(goal)}
                                      />
                                    )}
                                    {hasPermission(
                                      permissions,
                                      "Goal",
                                      "delete"
                                    ) && (
                                      <Button
                                        icon="pi pi-trash"
                                        className="p-button-rounded p-button-outlined"
                                        onClick={() =>
                                          handledeleteGoal(goal.id)
                                        }
                                      />
                                    )}
                                  </div>
                                </div>
                              </div>
                            </AccordionTab>
                          ))}
                        </Accordion>
                      </TabPanel>
                    )
                  );
                })}
              </TabView>
            ) : (
              <p>No Goals</p>
            )}
          </div>

          <SingleGoal
            childId={childId}
            goal={goal}
            setGoal={setGoal}
            handleSaveGoal={handleSaveGoal}
            editMode={goalEditMode}
            visible={isSingleGoalModalVisible}
            onHide={() => {
              setIsSingleGoalModalVisible(false);
              setGoalEditMode(false);
              setGoal((prev) => ({
                ...prev,
                id: null,
                name: "",
                description: "",
                startDate: Date.now(),
                goalDomainId: null,
                goalProgress: 0,
              }));
              setGoalDetails([
                {
                  activityDate: null,
                  goalActivityId: null,
                  // goalProgress: 0,
                },
              ]);
            }}
            goalDetails={goalDetails}
            setGoalDetails={setGoalDetails}
            loading={loading}
            setLoading={setLoading}
          />
        </div>
      </Dialog>
      {loading && (
        <div style={spinnerContainerStyle}>
          <ProgressSpinner />
        </div>
      )}
    </>
  );
};

const spinnerContainerStyle = {
  position: "fixed",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  zIndex: 1000,
};

export default ChildrenList;
