import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import React, { useEffect, useState } from "react";
import { getAllGoalDomains } from "../../api/goalDomainApi";
import { getAllGoalActivities } from "../../api/goalActivityApi";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Calendar } from "primereact/calendar";
import { ProgressBar } from "primereact/progressbar";
import { Slider } from "primereact/slider";

const SingleGoal = ({
  childId,
  goal,
  setGoal,
  goalDetails,
  setGoalDetails,
  handleSaveGoal,
  onHide,
  visible,
  loading,
  setLoading,
  editMode,
}) => {
  const [goalDomains, setGoalDomains] = useState([]);
  const [goalActivities, setGoalActivities] = useState([]);
  useEffect(() => {
    const fetchGoalDomains = async () => {
      setLoading(true);
      try {
        const goalDomains = await getAllGoalDomains();
        setGoalDomains(goalDomains);
      } catch {
        console.log("Error fetching goal domains");
      } finally {
        setLoading(false);
      }
    };
    const fetchGoalActivities = async () => {
      setLoading(true);
      try {
        const goalActivities = await getAllGoalActivities();
        setGoalActivities(goalActivities);
      } catch {
        console.log("Error fetching goal activities");
      } finally {
        setLoading(false);
      }
    };
    fetchGoalDomains();
    fetchGoalActivities();
  }, []);
  const addGoalDetailRecord = () => {
    setGoalDetails([
      ...goalDetails,
      { activityDate: null, goalActivityId: null, goalProgress: 0 },
    ]);
  };
  const removeGoalDetailRow = (index) => {
    if (goalDetails.length > 1) {
      const updatedGoalDetails = [...goalDetails];
      updatedGoalDetails.splice(index, 1);
      setGoalDetails(updatedGoalDetails);
    }
  };
  return (
    <Dialog
      visible={visible}
      onHide={onHide}
      header={editMode ? "Edit Goal" : "Add New Goal"}
      headerStyle={{ textAlign: "center" }}
      style={{
        width: "600px",
        maxWidth: "90%",
      }}
    >
      <div className="p-col-12 p-shadow-2 p-p-3 p-mb-3 mt-2 ">
        <div className="grid">
          <div className="col-12">
            <label>Name</label>
            <InputText
              value={goal.name}
              onChange={(e) =>
                setGoal((prev) => ({
                  ...prev,
                  name: e.target.value,
                }))
              }
              placeholder="Write Name"
              className="p-mb-2 mb-1 mt-1 full-width"
            />
          </div>{" "}
          <div className="col-6">
            <label>Domain</label>
            <br />
            <Dropdown
              value={goal?.goalDomainId}
              onChange={(e) =>
                setGoal((prev) => ({
                  ...prev,
                  goalDomainId: e.target.value,
                }))
              }
              options={goalDomains}
              optionLabel="name"
              optionValue="id"
              placeholder="Select Goal Domain"
              className="p-mb-2 mb-1 mt-1 full-width"
            />
          </div>{" "}
          <div className="col-6">
            <label>Start Date</label>
            <br />
            <Calendar
              value={goal.startDate ? new Date(goal.startDate) : null}
              onChange={(e) => {
                setGoal((prev) => ({
                  ...prev,
                  startDate: e.value,
                }));
              }}
              placeholder="Select Date"
              className="p-mb-2 mb-1 mt-1 full-width"
            />
          </div>
          <div className="col-12">
            <label>Description</label>
            <InputTextarea
              value={goal.description}
              onChange={(e) =>
                setGoal((prev) => ({
                  ...prev,
                  description: e.target.value,
                }))
              }
              placeholder="Write Description"
              className="p-mb-2 mb-1 mt-1 full-width "
              rows={3}
            />
          </div>
          {editMode && (
            <div className="col-12">
              <label>Goal Progress</label>
              <ProgressBar
                value={goal.goalProgress}
                style={{
                  height: "18px",
                  borderRadius: "0px",
                  color: "#655BC6",
                }} // Rounded corners to match slider style
                className="full-width border-0 mt-1"
              />

              <Slider
                value={goal.goalProgress}
                onChange={(e) =>
                  setGoal((prev) => ({
                    ...prev,
                    goalProgress: e.value,
                  }))
                }
                style={{
                  height: "5px",
                  borderRadius: "0px",
                  color: "#655BC6",
                }} // Rounded corners to match slider style
                className=" m-0  full-width  border-0 "
              />
            </div>
          )}
          {goalDetails.map((goalDetail, index) => (
            <>
              <div className="col-6">
                <label>Activity</label>
                <br />
                <Dropdown
                  value={goalDetail?.goalActivityId}
                  onChange={(e) =>
                    setGoalDetails((prev) =>
                      prev.map((detail, i) =>
                        i === index
                          ? { ...detail, goalActivityId: e.value }
                          : detail
                      )
                    )
                  }
                  options={goalActivities}
                  optionLabel="name"
                  optionValue="id"
                  placeholder="Select Goal Activity"
                  className="p-mb-2 mb-1 mt-1 full-width"
                />
              </div>
              <div className="col-6">
                <label>Activity Date</label>
                <br />
                <Calendar
                  value={
                    goalDetail.activityDate
                      ? new Date(goalDetail.activityDate)
                      : null
                  }
                  onChange={(e) => {
                    setGoalDetails((prev) =>
                      prev.map((detail, i) =>
                        i === index
                          ? { ...detail, activityDate: e.value }
                          : detail
                      )
                    );
                  }}
                  placeholder="Select Date"
                  dateFormat="dd/mm/yy"
                  className="p-mb-2 mb-1 mt-1 full-width"
                />
              </div>
              <div
                className="col-12 p-d-flex p-jc-center p-ai-center"
                style={{ float: "right" }}
              >
                <Button
                  icon="pi pi-plus"
                  className="p-button-sm p-button-rounded p-button-success"
                  onClick={addGoalDetailRecord}
                  style={{ marginRight: "5px" }}
                />
                <Button
                  icon="pi pi-minus"
                  className="p-button-sm p-button-rounded p-button-danger"
                  onClick={() => removeGoalDetailRow(index)}
                />
              </div>
            </>
          ))}
          <div className="col-12 mb-2 mt-2">
            <Button
              label={editMode ? "Update Goal" : "Add Goal"}
              onClick={handleSaveGoal}
              className="btn-primary"
            />
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default SingleGoal;
