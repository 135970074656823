import React, { useState, useEffect } from 'react';
import { InputText } from 'primereact/inputtext';
import { MultiSelect } from 'primereact/multiselect';
import { Button } from 'primereact/button';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { getPermissions } from '../../api/permissonApi';
import { getRolePermissions } from '../../api/roleApi';

const SingleRole = ({ role, setRole, onSubmit, isEdit }) => {
    const [permissionGroups, setPermissionGroups] = useState([]);
    const [loading, setLoading] = useState(true);
    // console.log("role", role);
    useEffect(() => {
        const fetchPermissions = async () => {
            try {
                const data = await getPermissions();
                setPermissionGroups(data);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching permissions', error);
            }
        };

        fetchPermissions();

        if (isEdit) {
            const fetchRolePermissions = async () => {
                try {
                    const rolePermissions = await getRolePermissions(role.id);
                    setRole((prevState) => ({
                        ...prevState,
                        permissions: rolePermissions,
                    }));
                    console.log("updated role",role);
                } catch (error) {
                    console.error('Error fetching role permissions', error);
                }
            };

            fetchRolePermissions();
        }
        // isEdit, role.id, setRole
    }, []);
    const handleChange = (e) => {
        const { name, value } = e.target;
        setRole((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handlePermissionChange = (groupName, selectedPermissions) => {
        setRole((prevState) => ({
            ...prevState,
            permissions: {
                ...prevState.permissions,
                [groupName]: selectedPermissions,
            },
        }));
        console.log("updated role 2",role);
    };

    return (
        <div className="form-group">
            <div className="p-fluid">
                <div className="p-field">
                    <label htmlFor="name">Role Name</label>
                    <InputText
                        id="name"
                        name="name"
                        value={role.name}
                        onChange={handleChange}
                        required
                    />
                </div>
                <label className='mb-2'>Role Permissions</label>
                <Accordion multiple>
                    {Object.entries(permissionGroups).map(([moduleName, modulePermissions]) => (
                        <AccordionTab key={moduleName} header={moduleName}>
                            <div className="p-field">
                                {/* <label htmlFor={moduleName}>{moduleName} Permissions</label> */}
                                <MultiSelect
                                    id={moduleName}
                                    // role.permissions[moduleName] || 
                                    value={role.permissions?.[moduleName] || []}
                                    options={modulePermissions.map((perm) => ({
                                        label: `${perm.action} ${perm.subject}`,
                                        value: perm.id,
                                    }))}
                                    onChange={(e) => handlePermissionChange(moduleName, e.value)}
                                    optionLabel="label"
                                    optionValue="value"
                                    // placeholder={`Select ${moduleName} Permissions`}
                                    display="chip"
                                    style={{ width: '100%' }}
                                />
                            </div>
                        </AccordionTab>
                    ))}
                </Accordion>
                <Button
                    label={isEdit ? 'Update Role' : 'Add Role'}
                    className='btn-primary mt-2'
                    onClick={onSubmit}
                />
            </div>
        </div>
    );
};

export default SingleRole;
