import React, { useState } from 'react';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';


const SingleDeviceBrand = ({ deviceBrand, setDeviceBrand, onSubmit, isEdit }) => {

  const handleChange = (e) => {
    const { name, value } = e.target;
    setDeviceBrand((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const deviceTypes = [
    { label: 'Hearing Aid', value: 'Hearing Aid' },
    { label: 'Cochlear Implant', value: 'Cochlear Implant' },
  ];
  console.log("deviceBrand", deviceBrand);
  return (
    <div className="form-group">
      <div className="p-fluid">

        <div className="p-field">
          <label htmlFor="name">Name</label>
          <InputText
            id="name"
            name="name"
            value={deviceBrand.name}
            onChange={handleChange}
          />
        </div>
        <div className="p-field">
          <label htmlFor="deviceType">Device Type</label>
          <Dropdown
            id="deviceType"
            name="deviceType"
            value={deviceBrand.deviceType}
            options={deviceTypes}
            onChange={handleChange}
            placeholder="Select a Device Type"
            required
          />
        </div>

        <Button
          label={isEdit ? 'Update Device Brand' : 'Add Device Brand'}
          className='btn-primary mt-2'
          onClick={onSubmit}
        />
      </div>
    </div>
  );
};

export default SingleDeviceBrand;
