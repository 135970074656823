import React, { useState, useEffect } from "react";
// import { Button, Dialog, InputText } from 'primereact';
import { Dialog } from "primereact/dialog";
import { toast } from "react-toastify";
import SummaryCard from "../Common/SummaryCards";
import DynamicDataTable from "../Common/DataListing";
import SingleRole from "../User/SingleRole";
import {
  getRoles,
  createRole,
  updateRole,
  deleteRole,
} from "../../api/roleApi";

const RolesList = () => {
  const [roles, setRoles] = useState([]);
  const [roleData, setRoleData] = useState({ name: "" });
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  // const [summaryData, setSummaryData] = useState({ totalRoles: 0 });

  useEffect(() => {
    fetchRoles();
  }, []);

  const fetchRoles = async () => {
    const data = await getRoles();
    setRoles(data);
    // setSummaryData({ totalRoles: data.length });
  };
  const responseAlert = (response, autoClose) => {
    if (response.success) {
      toast.success(response.message, { autoClose: autoClose });
    } else {
      toast.error(response.message, { autoClose: autoClose });
    }
  };

  const handleSubmit = async () => {
    try {
      let response = {};
      if (isEdit) {
        response = await updateRole(roleData.id, roleData);
      } else {
        response = await createRole(roleData);
      }
      responseAlert(response, 300);
      fetchRoles(); // Refresh the list after submission
      setIsModalVisible(false);
    } catch (error) {
      console.error("Error saving role", error);
    }
  };

  const handleDelete = async (id) => {
    if (id <= 4) {
      toast.error("You can't delete this role", { autoClose: 300 });
      return;
    } else {
      const response = await deleteRole(id);
      responseAlert(response);
      fetchRoles();
    }
  };

  const handleEdit = (rowData) => {
    setIsEdit(true);
    setRoleData(rowData);
    setIsModalVisible(true);
  };

  const handleCreate = () => {
    setIsEdit(false);
    setRoleData({
      name: "",
      permissions: {},
    });
    setIsModalVisible(true);
  };

  return (
    <div>
      {/* <SummaryCard title="Total Roles" value={summaryData.totalRoles} /> */}
      <DynamicDataTable
        data={roles}
        columns={[
          { field: "name", header: "Role" },
          // { field: 'description', header: 'Description' }
        ]}
        onCreate={handleCreate}
        onEdit={handleEdit}
        onDelete={handleDelete}
      />
      <Dialog
        header={isEdit ? "Edit Role" : "Add New Role"}
        visible={isModalVisible}
        style={{ width: "30vw" }}
        // className='form-box'
        onHide={() => setIsModalVisible(false)}
      >
        <SingleRole
          role={roleData}
          setRole={setRoleData}
          onSubmit={handleSubmit}
          isEdit={isEdit}
        />
      </Dialog>
    </div>
  );
};

export default RolesList;
