import axios from "axios";

const API_URL = "/api/goaldomains";
export const createGoalDomain = async (domain) => {
  const response = await axios.post(API_URL, domain);
  return response.data;
};
export const getAllGoalDomains = async () => {
  const response = await axios.get(API_URL);
  return response.data;
};
export const deleteGoalDomain = async (id) => {
  const response = await axios.delete(`${API_URL}/${id}`);
  return response.data;
};
export const updateGoalDomain = async (id, domain) => {
  const response = await axios.put(`${API_URL}/${id}`, domain);
  return response.data;
};
