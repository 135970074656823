import axios from "axios";

const API_URL = "/api/weeklyplan"; // Adjust this URL to your backend API endpoint

export const getChildWeeklyPlans = async (childId) => {
  try {
    const response = await axios.get(`${API_URL}/${childId}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching Weekly Plans:", error);
    throw error;
  }
};
// export const getAllWeeklyPlans = async () => {
//   try {
//     const response = await axios.get(`${API_URL}`);
//     return response.data;
//   } catch (error) {
//     console.error("Error fetching Weekly Plans:", error);
//     throw error;
//   }
// };

export const addWeeklyPlan = async (planData) => {
  try {
    const response = await axios.post(API_URL, planData);
    return response.data;
  } catch (error) {
    console.error("Error adding Weekly Plan:", error);
    throw error;
  }
};
export const editWeeklyPlan = async (id, planData) => {
  try {
    const response = await axios.put(`${API_URL}/${id}`, planData);
    return response.data;
  } catch (error) {
    console.error("Error updating Weekly Plan:", error);
    throw error;
  }
};
export const deleteWeeklyPlan = async (id) => {
  try {
    const response = await axios.delete(`${API_URL}/${id}`);
    return response.data;
  } catch (error) {
    console.error("Error deleting WeeklyPlan:", error);
    throw error;
  }
};
export const updateWeeklyPlanField = async (id, newValue, field) => {
  try {
    const response = await axios.put(`${API_URL}/update-field/${id}`, {
      newValue,
      field,
    });
    return response.data;
  } catch (err) {
    console.log(err);
  }
};
