export const formatDuration = (value) => {
  const hours = Math.floor(value / 60);
  const minutes = value % 60;
  return `${hours ? `${hours} hour${hours > 1 ? "s" : ""}` : ""}${
    minutes ? ` ${minutes} minute${minutes > 1 ? "s" : ""}` : ""
  }`;
};
export const formatTimeTo12HourFormat = (time) => {
  // Split the time into hours, minutes, and seconds
  const [hours, minutes, seconds] = time.split(":").map(Number);

  // Determine whether it's AM or PM
  const period = hours >= 12 ? "PM" : "AM";

  // Convert hours to 12-hour format
  const twelveHour = hours % 12 || 12; // If hour is 0, display as 12

  // Format the time in 12-hour format with leading zero for minutes
  const formattedTime = `${twelveHour}:${minutes
    .toString()
    .padStart(2, "0")} ${period}`;

  return formattedTime;
};
export const formatToCamelCase = (data) => {
  if (!data) return ""; // Handle empty strings or falsy values

  const firstLetter = data.slice(0, 1).toUpperCase();
  const rest = data.slice(1).toLowerCase();
  return firstLetter + rest;
};
