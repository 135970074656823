import axios from "axios";

const API_URL = "/api/appointments"; // Adjust this URL to your backend API endpoint

export const getAllAppointments = async () => {
  try {
    const response = await axios.get(`${API_URL}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching Appointments:", error);
    throw error;
  }
};

export const addAppointment = async (appointmentData) => {
  try {
    const response = await axios.post(API_URL, appointmentData);
    return response.data;
  } catch (error) {
    console.error("Error adding Appointment:", error);
    throw error;
  }
};
export const editAppointment = async (id, appointmentData) => {
  try {
    const response = await axios.put(`${API_URL}/${id}`, appointmentData);
    return response.data;
  } catch (error) {
    console.error("Error updating Appointment:", error);
    throw error;
  }
};
export const deleteAppointment = async (id) => {
  try {
    const response = await axios.delete(`${API_URL}/${id}`);
    return response.data;
  } catch (error) {
    console.error("Error deleting Appointment:", error);
    throw error;
  }
};
export const updateAppointmentField = async (id, newValue, field) => {
  try {
    const response = await axios.put(`${API_URL}/update-field/${id}`, {
      newValue,
      field,
    });
    return response.data;
  } catch (err) {
    console.log(err);
  }
};
