// src/components/Layout.js
import React, { useRef, useEffect, useState } from "react";
import { Menubar } from "primereact/menubar";
import { PanelMenu } from "primereact/panelmenu";
import { TieredMenu } from "primereact/tieredmenu";
import { Button } from "primereact/button";
import { Navigate, useNavigate, Outlet, useLocation } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";
import { fetchUserPermissions } from "../api/userApi";
import { hasPermission } from "../utils/permissions";
import "../styles/layout.scss";
const Layout = () => {
  const [permissions, setPermissions] = useState([]);
  const { logout, user } = useAuth();
  const location = useLocation();
  useEffect(() => {
    const getPermissions = async () => {
      try {
        const userPermissions = await fetchUserPermissions(user.id);
        setPermissions(userPermissions);
      } catch (error) {
        console.error("Error fetching permissions:", error);
      }
    };

    getPermissions();
  }, []);

  // console.log(user.permissions);
  const menuRef = useRef(null);
  const navigate = useNavigate();
  const menuItems = [
    {
      label: "Dashboard",
      icon: "pi pi-fw pi-home",
      // command: () => navigate('/dashboard'),
      visible: hasPermission(permissions, "Dashboard", "read"),
    },
    {
      label: "Users",
      icon: "pi pi-fw pi-users",
      command: () => navigate("/index/users"),
      visible: hasPermission(permissions, "User", "read"),
    },
    {
      label: "Roles",
      icon: "pi pi-fw pi-lock",
      command: () => navigate("/index/roles"),
      visible: hasPermission(permissions, "Role", "read"),
    },
    // {
    //     label: 'Permissions',
    //     icon: 'pi pi-fw pi-key',
    //     command: () => <Navigate to={'/permissions'} />,
    //     // visible: ability.can('read', 'Permissions')
    // },
    {
      label: "Services",
      icon: "pi pi-fw pi-box",
      command: () => navigate("/index/services"),
      visible: hasPermission(permissions, "Service", "read"),
    },
    {
      label: "Plans",
      icon: "pi pi-fw pi-building",
      command: () => navigate("/index/plans"),
      visible: hasPermission(permissions, "Plan", "read"),
    },
    {
      label: "Subscriptions",
      icon: "pi pi-fw pi-refresh",
      command: () => navigate("/index/subscriptions"),
      visible: hasPermission(permissions, "Subscription", "read"),
    },
    {
      label: "Payments",
      icon: "pi pi-fw pi-wallet",
      command: () => navigate("/index/payments"),
      visible: hasPermission(permissions, "Payment", "read"),
    },
    {
      label: "Children",
      icon: "pi pi-fw pi-face-smile",
      command: () => navigate("/index/children"),
      visible: hasPermission(permissions, "Child", "read"),
    },
    {
      label: "Device Brands",
      icon: "pi pi-fw pi-server",
      command: () => navigate("/index/devicebrands"),
      visible: hasPermission(permissions, "Device Brand", "read"),
    },
    {
      label: "Device Models",
      icon: "pi pi-fw pi-objects-column",
      command: () => navigate("/index/devicemodels"),
      visible: hasPermission(permissions, "Device Brand", "read"),
    },
    {
      label: "Announcements",
      icon: "pi pi-fw pi-bell",
      command: () => <Navigate to={"/permissions"} />,
      visible: hasPermission(permissions, "Announcement", "read"),
    },
    {
      label: "Appointments",
      icon: "pi pi-fw pi-calendar-clock",
      command: () => navigate("/index/appointments"),
      visible: hasPermission(permissions, "Appointment", "read"),
    },
    {
      label: "Goal Domains",
      icon: "pi pi-th-large",
      command: () => navigate("/index/goaldomains"),
      visible: hasPermission(permissions, "Goal Domain", "read"),
    },
    {
      label: "Goal Activities",
      icon: "pi pi-check-square",
      command: () => navigate("/index/goalactivities"),
      visible: hasPermission(permissions, "Goal Activity", "read"),
    },
    {
      label: "Settings",
      icon: "pi pi-fw pi-cog",
      command: () => navigate("/index/settings"),
      visible: hasPermission(permissions, "Setting", "read"),
    },
  ];

  const userMenuItems = [
    {
      label: "Profile",
      icon: "pi pi-fw pi-user",
      // command: () => <Navigate to={'/profile'} />
      command: () => navigate("/index/profile"),
    },
    {
      label: "Logout",
      icon: "pi pi-fw pi-sign-out",
      command: logout,
    },
  ];
  const end = (
    <>
      <TieredMenu model={userMenuItems} popup ref={menuRef} />
      <Button
        className="bg-prmary"
        icon="pi pi-bars"
        onClick={(event) => menuRef.current.toggle(event)}
      />
    </>
  );

  return (
    <div className="layout">
      <aside className="layout-sidebar pt-2">
        <img alt="logo" src="/logo.png" height="60" className="logo" />
        <PanelMenu model={menuItems} />
      </aside>
      <header className="layout-header">
        <Menubar end={end} />
        <main className="layout-content">
          <Outlet />
        </main>
      </header>
    </div>
  );
};

export default Layout;
