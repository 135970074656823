import React, { useEffect, useState } from "react";
import { Calendar } from "primereact/calendar";
import {
  format,
  startOfWeek,
  endOfWeek,
  getWeek,
  startOfMonth,
  getMonth,
  differenceInCalendarDays,
  getWeeksInMonth,
  addDays,
} from "date-fns";
import { Dropdown } from "primereact/dropdown";
import { InputTextarea } from "primereact/inputtextarea";
import { Button } from "primereact/button";

const SingleWeeklyPlan = ({
  // childId,
  showTherapists,
  allTherapists,
  weeklyPlan,
  setWeeklyPlan,
  handleSaveWeeklyPlan,
  planEditMode,
}) => {
  const [weekOptions, setWeekOptions] = useState([]);
  const [currentMonth, setCurrentMonth] = useState(
    planEditMode ? new Date(`${weeklyPlan.planMonth}-01`) : new Date()
  ); // Current month as Date object

  // Generate weeks for a specific month (default is the current month)
  const generateWeeksForMonth = (month) => {
    const firstDayOfMonth = startOfMonth(month);
    const weeksInMonth = Math.ceil(
      new Date(month.getFullYear(), month.getMonth() + 1, 0).getDate() / 7
    ); // Corrected method to calculate weeks in month
    const weeks = [];

    for (let i = 0; i < weeksInMonth; i++) {
      const startWeek = startOfWeek(addDays(firstDayOfMonth, i * 7), {
        weekStartsOn: 1,
      }); // Monday as start
      const endWeek = endOfWeek(startWeek, { weekStartsOn: 1 }); // Sunday as end
      const formattedWeek = `${format(startWeek, "dd/MM/yyyy")} - ${format(
        endWeek,
        "dd/MM/yyyy"
      )}`;
      weeks.push(`Week ${i + 1}: ${formattedWeek}`);
    }

    setWeekOptions(weeks);
  };

  useEffect(() => {
    generateWeeksForMonth(currentMonth);
  }, [currentMonth]);
  const handleWeekChange = (e) => {
    if (weeklyPlan.planMonth === "" || weeklyPlan.planMonth === undefined) {
      const formattedMonth = format(currentMonth, "MMMM-yyyy");
      setWeeklyPlan((prev) => ({
        ...prev,
        planMonth: formattedMonth,
      }));
    }
    setWeeklyPlan((prev) => ({
      ...prev,
      planWeek: e.value, // Save the selected week label
    }));
  };

  const handleMonthChange = (e) => {
    const selectedDate = new Date(e.value); // Create a Date object from the selected month
    setCurrentMonth(selectedDate);

    // Format the month as "Month-Year" (e.g., "September-2024")
    const formattedMonth = format(selectedDate, "MMMM-yyyy");

    setWeeklyPlan((prev) => ({
      ...prev,
      planMonth: formattedMonth, // Save the month in "Month-Year" format
      planWeek: "",
    }));
    console.log(weeklyPlan.planMonth);

    generateWeeksForMonth(selectedDate); // Generate weeks for the selected month
  };

  return (
    <div>
      {/* {planEditMode ? <h2>Edit Weekly Plan</h2> : <h2>Add Weekly Plan</h2>} */}
      <div className="grid">
        <div className="col-6">
          <label>Month</label>
          <Calendar
            value={
              weeklyPlan.planMonth
                ? new Date(`${weeklyPlan.planMonth}-01`) // Convert "Month-Year" to a Date (set the day as 01)
                : currentMonth
            }
            onChange={handleMonthChange}
            view="month"
            dateFormat="mm/yy"
            className="full-width"
          />
        </div>
        {weekOptions.length > 0 && (
          <div className="col-6">
            <label>Week</label>
            <Dropdown
              value={weeklyPlan.planWeek}
              options={weekOptions}
              onChange={handleWeekChange}
              optionLabel="label"
              placeholder="Select a week"
              className="full-width"
            />
          </div>
        )}
        {showTherapists && (
          <div className="col-6">
            <label>Therapist</label>
            <Dropdown
              value={weeklyPlan.therapistId}
              onChange={(e) =>
                setWeeklyPlan((prev) => ({
                  ...prev,
                  therapistId: e.value,
                }))
              }
              options={allTherapists}
              optionLabel="name"
              optionValue="id"
              placeholder="Select Therapist"
              className="full-width"
            />
          </div>
        )}
        <div className="col-12">
          <label>Plan Details</label>
          <InputTextarea
            value={weeklyPlan.planDetails}
            onChange={(e) =>
              setWeeklyPlan((prev) => ({
                ...prev,
                planDetails: e.target.value,
              }))
            }
            rows={5}
            cols={30}
            autoResize
            placeholder="Enter plan details"
            className="full-width"
          />
        </div>
        {/* {weeklyPlan.planWeek && (
          <div>
            <h3>Selected Week:</h3>
            <p>{weeklyPlan.planWeek}</p>
            <p>{weeklyPlan.planMonth}</p>
          </div>
        )} */}
      </div>
      <Button label="Save" onClick={handleSaveWeeklyPlan} />
    </div>
  );
};

export default SingleWeeklyPlan;
