import axios from "axios";

const API_URL = "/api/users"; // Adjust this URL to your backend API endpoint

export const getUsers = async () => {
  try {
    const response = await axios.get(API_URL);
    return response.data;
  } catch (error) {
    console.error("Error fetching users:", error);
    throw error;
  }
};

export const getUserById = async (userId) => {
  try {
    const response = await axios.get(`${API_URL}/${userId}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching user:", error);
    throw error;
  }
};

export const addUser = async (userData) => {
  try {
    const response = await axios.post(API_URL, userData);
    return response.data;
  } catch (error) {
    console.error("Error adding user:", error);
    throw error;
  }
};

export const editUser = async (userId, userData) => {
  console.log("editUser", userData);
  try {
    const response = await axios.put(`${API_URL}/${userId}`, userData);
    return response.data;
  } catch (error) {
    console.error("Error editing user:", error);
    throw error;
  }
};

export const deleteUser = async (userId) => {
  try {
    const response = await axios.delete(`${API_URL}/${userId}`);
    return response.data;
  } catch (error) {
    console.error("Error deleting user:", error);
    throw error;
  }
};
export const fetchUserPermissions = async (userId) => {
  try {
    const response = await axios.get(`${API_URL}/${userId}/permissions`);
    // console.log("user Role Permissions", response.data);

    return response.data;
  } catch (error) {
    console.error("Error fetching user permissions:", error);
    throw error;
  }
};

export const getUsersByRole = async (roleId) => {
  try {
    const response = await axios.get(`${API_URL}/${roleId}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching users:", error);
    throw error;
  }
};
export const editUserPermissions = async (userId, permissions) => {
  try {
    // console.log("permissions", permissions);

    const response = await axios.put(
      `${API_URL}/${userId}/update-permissions`,
      permissions
    );
    return response.data;
  } catch (error) {
    console.error("Error editing user permissions:", error);
    throw error;
  }
};
export const getUserDirectPermissions = async (userId) => {
  // console.log(userId);

  try {
    const response = await axios.get(`${API_URL}/${userId}/direct-permissions`);
    return response.data; // Assuming the API returns the grouped permissions data directly
  } catch (error) {
    console.error("Error fetching role permissions", error);
    throw error;
  }
};
