import axios from 'axios';

const API_URL = '/api/subscriptions';

export const createSubscription = async (subscriptionData) => {
    const response = await axios.post(API_URL, subscriptionData);
    return response.data;
};

export const isServiceAlreadySubscribed = async (subscriptionData) => {
    const response = await axios.post(`${API_URL}/isServiceSubscribed`, subscriptionData);
    return response.data;
};

export const isPlanAlreadySubscribed = async (subscriptionData) => {
    const response = await axios.post(`${API_URL}/isPlanAlreadySubscribed`, subscriptionData);
    return response.data;
};

export const getSubscriptions = async () => {
    const response = await axios.get(API_URL);
    return response.data;
};

export const updateSubscription = async (id, subscriptionData) => {
    const response = await axios.put(`${API_URL}/${id}`, subscriptionData);
    return response.data;
};

export const deleteSubscription = async (id) => {
    const response = await axios.delete(`${API_URL}/${id}`);
    return response.data;
};
