import axios from "axios";

const API_URL = "/api/childsoapnote"; // Adjust this URL to your backend API endpoint

export const getChildSoapNoteById = async (userId) => {
  try {
    const response = await axios.get(`${API_URL}/${userId}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching SOAP Notes:", error);
    throw error;
  }
};

export const addChildSoapNote = async (noteData) => {
  try {
    const response = await axios.post(API_URL, noteData);
    return response.data;
  } catch (error) {
    console.error("Error adding SOAP Notes:", error);
    throw error;
  }
};
export const updateChildSoapNote = async (userId, noteData) => {
  try {
    const response = await axios.put(`${API_URL}/${userId}`, noteData);
    return response.data;
  } catch (error) {
    console.error("Error updating SOAP Notes:", error);
    throw error;
  }
};
export const deleteChildSoapNote = async (userId) => {
  try {
    const response = await axios.delete(`${API_URL}/${userId}`);
    return response.data;
  } catch (error) {
    console.error("Error deleting SOAP Notes:", error);
    throw error;
  }
};
