import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import { InputTextarea } from "primereact/inputtextarea";
import React from "react";

const SingleChildSoapNote = ({
  newNote,
  setNewNote,
  services,
  handleSaveNote,
  editMode,
  showTherapists,
  allTherapists,
}) => {
  //   const editMode = mode === "edit" ? true : false;
  return (
    <div className="p-col-12 p-shadow-2 p-p-3 p-mb-3 mt-2 ">
      {/* {editMode ? null : (
        <p className="fs-1 text-center font-bold">Add New SOAP Note</p>
      )} */}
      <div className="grid">
        <div className="col-6">
          <label>Service</label>
          <br />
          <Dropdown
            value={newNote.serviceId}
            onChange={(e) =>
              setNewNote((prev) => ({
                ...prev,
                serviceId: e.target.value,
              }))
            }
            options={services}
            optionLabel="name"
            optionValue="id"
            placeholder="Select Service"
            // rows={3}
            className="p-mb-2 mb-1 mt-1"
          />
        </div>

        <div className="col-6">
          <label>Date</label>
          <br />
          <Calendar
            value={newNote.noteDate ? new Date(newNote.noteDate) : null}
            // value={newNote.noteDate}
            onChange={(e) =>
              setNewNote((prev) => ({
                ...prev,
                noteDate: e.target.value,
              }))
            }
            placeholder="Enter Date"
            className="p-mb-2 mb-1 mt-1"
            maxDate={new Date()}
          />
        </div>
        {showTherapists && (
          <div className="col-12">
            <label>Therapist</label>
            <Dropdown
              value={newNote.therapistId}
              onChange={(e) =>
                setNewNote((prev) => ({
                  ...prev,
                  therapistId: e.target.value,
                }))
              }
              options={allTherapists}
              optionLabel="name"
              optionValue="id"
              placeholder="Select Therapist"
              // rows={3}
              className="p-mb-2 mb-1 mt-1"
            />
          </div>
        )}
        <div className="col-6">
          <label>Subjective</label>
          <InputTextarea
            value={newNote.subjective}
            onChange={(e) =>
              setNewNote((prev) => ({
                ...prev,
                subjective: e.target.value,
              }))
            }
            placeholder="Write Subjective"
            className="p-mb-2 mb-1 mt-1"
            rows={3}
          />
        </div>
        <div className="col-6">
          <label>Objective</label>
          <InputTextarea
            value={newNote.objective}
            onChange={(e) =>
              setNewNote((prev) => ({
                ...prev,
                objective: e.target.value,
              }))
            }
            placeholder="Write Objective"
            rows={3}
            className="p-mb-2 mb-1 mt-1"
          />
        </div>
        <div className="col-6">
          <label>Assessment</label>
          <InputTextarea
            value={newNote.assessment}
            onChange={(e) =>
              setNewNote((prev) => ({
                ...prev,
                assessment: e.target.value,
              }))
            }
            placeholder="Write Assessment"
            rows={3}
            className="p-mb-2 mb-1 mt-1"
          />
        </div>
        <div className="col-6">
          <label>Plan</label>
          <InputTextarea
            value={newNote.plan}
            onChange={(e) =>
              setNewNote((prev) => ({ ...prev, plan: e.target.value }))
            }
            placeholder="Write Plan"
            rows={3}
            className="p-mb-2 mb-1 mt-1"
          />
        </div>
        <div className="col-12">
          <label>Note</label>
          <InputTextarea
            value={newNote.note}
            onChange={(e) =>
              setNewNote((prev) => ({ ...prev, note: e.target.value }))
            }
            placeholder="Write Additional Note"
            rows={3}
            className="p-mb-2 mb-1 mt-1"
          />
        </div>
        <div className="col-12 mb-2 mt-2">
          <Button
            label={editMode ? "Update Note" : "Add Note"}
            onClick={handleSaveNote}
            className="btn-primary"
          />
        </div>
      </div>
    </div>
  );
};

export default SingleChildSoapNote;
