import React from 'react';
import { Menubar } from 'primereact/menubar';
import { Link } from 'react-router-dom';
import '../styles/home.css';
import '../styles/buttons.scss';

const Home = () => {
  const start = (
    <img
      alt="logo"
      src="/logo.png"
      height="60"
      className="logo"
    />
  );
  const end = (
    <div>
      {/* <button style={{ marginRight: '0.1rem' }} className="btn-primary">Login</button> */}
      <Link to="/login" className="btn-primary" style={{ marginRight: '0.1rem' }}>
        Login
      </Link>
      <Link to="/register" className="btn-secondary" style={{ marginRight: '0.1rem' }}>
        Register
      </Link>
      {/* <button className="btn-secondary">Register</button> */}
    </div>
  );
  return (
    <>
      <div>
        <Menubar start={start} end={end} />
        {/* style={{ backgroundImage: 'url(/hero.jpg)' }} */}
        <div className="hero-banner" >
          <div className="hero-content">
            <h1>Welcome to Terteeb!</h1>
            <p>Helping children & teens
              Achieve their true potential</p>
            <Link to="/register" className="btn-primary" style={{ marginRight: '0.1rem' }}>
              Get Started</Link>
          </div>
        </div>
      </div>
    </>
  );
};
export default Home;