import axios from 'axios';

const API_URL = '/api/devicemodels';

export const createDeviceModel = async (deviceModelData) => {
    const response = await axios.post(API_URL, deviceModelData);
    return response.data;
};

export const getDeviceModels = async () => {
    const response = await axios.get(API_URL);
    return response.data;
};

export const updateDeviceModel = async (id, deviceModelData) => {
    const response = await axios.put(`${API_URL}/${id}`, deviceModelData);
    return response.data;
};

export const deleteDeviceModel = async (id) => {
    const response = await axios.delete(`${API_URL}/${id}`);
    return response.data;
};
