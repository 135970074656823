import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify'
import { getServices, createService, updateService, deleteService } from '../../api/serviceApi';
import { isServiceAlreadySubscribed, createSubscription } from '../../api/subscriptionApi';
import { getChildren } from '../../api/childApi';
import SingleService from './SingleService';
import DataListing from '../Common/DataListing';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { InputNumber } from 'primereact/inputnumber';
import { useAuth } from '../../contexts/AuthContext';
import { Dropdown } from 'primereact/dropdown';

const ServicesList = () => {
    const { user } = useAuth();
    const [services, setServices] = useState([]);
    const [children, setChildren] = useState([]);
    const [selectedChild, setSelectedChild] = useState();
    const [serviceData, setServiceData] = useState({
        name: '',
        serviceType: '',
        details: '',
        onlineFee: null,
        onsiteFee: null,
    });  //to be used for SingleService (Add/Edit)
    const [subscriptionData, setSubscriptionData] = useState({
        serviceId: null,
        userId: user.id,
        sessionsPerMonth: 1
    });  //to be used for Servic Subscription
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isSubscriptionModalVisible, setIsSubscriptionModalVisible] = useState(false);
    const [isEdit, setIsEdit] = useState(false);

    // Enum Mapping
    const serviceTypeLabels = {
        'hourly': 'Hourly',
        'one_time': 'One Time'
    };

    const renderserviceType = (rowData) => {
        return (<span>{serviceTypeLabels[rowData.serviceType] || 'Unknown'}</span>)
    };
    const [columns, setColumns] = useState([
        { field: 'name', header: 'Name' },
        { field: 'serviceType', header: 'Type', body: renderserviceType },
        { field: 'details', header: 'Detail' }

    ]);//to be used for DataListing

    useEffect(() => {
        fetchServices();
        fetchChildren();
    }, []);

    const fetchServices = async () => {
        try {
            const response = await getServices();
            setServices(response);
        } catch (error) {
            console.error('Failed to fetch services', error);
        }
    };
    const fetchChildren = async () => {
        try {
            const response = await getChildren();
            setChildren(response);
        } catch (error) {
            console.error('Failed to fetch services', error);
        }
    };

    const responseAlert = (response, autoClose) => {
        if (response.success) {
            toast.success(response.msg, { autoClose: autoClose })
        }
        else {
            toast.error(response.msg, { autoClose: autoClose })
        }

    }

    const handleDelete = async (id) => {
        try {
            await deleteService(id);
            fetchServices();
        } catch (error) {
            console.error('Failed to delete service', error);
        }
    };

    const handleEdit = (rowData) => {
        setIsEdit(true);
        setServiceData(rowData);
        setIsModalVisible(true);
    };

    const handleCreate = () => {
        setIsEdit(false);
        setServiceData({
            name: '',
            serviceType: '',
            details: ''
        });
        setIsModalVisible(true);
    };

    const handleSubmit = async () => {
        try {
            let response = {}
            if (isEdit) {
                response = await updateService(serviceData.id, serviceData);
            } else {
                response = await createService(serviceData);
            }
            // console.log(response.data);
            responseAlert(response, 300);
            fetchServices(); // Refresh the list after submission
            setIsModalVisible(false);
        } catch (error) {
            console.error('Error saving service', error);
        }
    };
    const handleSubscriptionSubmit = async (data = subscriptionData) => {

        const isSubscribed = await isServiceAlreadySubscribed(subscriptionData);
        if (isSubscribed.success) {
            toast.error("Service Already Subscribed!", { autoClose: 300 })
            return;
        }
        let response = await createSubscription(data);
        setIsSubscriptionModalVisible(false);
        responseAlert(response);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        console.log(name, value);
        setSubscriptionData((prevState) => ({
            ...prevState,
            [name]: value,
        }));
        if (name == 'childId') {
            setSelectedChild(value);
        }
    };


    const handleSubscription = async (rowData) => {

        const updatedSubscriptionData = {
            ...subscriptionData,
            serviceId: rowData.id,
            userId: user.id,
        };
        setSubscriptionData(updatedSubscriptionData);
        setIsSubscriptionModalVisible(true);
       
    };

    const serviceSubscriptionAction = [
        {
            // icon: 'pi pi-user-edit',
            label: 'Subscribe',
            className: 'btn-outline-primary',
            handler: handleSubscription,
            condition: (rowData) => true
        }
    ];

    return (
        <>
            <DataListing
                data={services}
                subject={"Service"}
                columns={columns}
                onCreate={handleCreate}
                onEdit={handleEdit}
                onDelete={handleDelete}
                extraActions={serviceSubscriptionAction}
            />
            <Dialog
                header="Service Details"
                visible={isModalVisible}
                style={{ width: '30vw' }}
                onHide={() => setIsModalVisible(false)}>
                <SingleService
                    service={serviceData}
                    setService={setServiceData}
                    onSubmit={handleSubmit}
                    isEdit={isEdit}
                />
            </Dialog>
            <Dialog
                header="Subscription Details"
                visible={isSubscriptionModalVisible}
                style={{ width: '20vw' }}
                onHide={() => setIsSubscriptionModalVisible(false)}>

                <div className="form-group">
                    <div className="p-field">
                        <label htmlFor="childId">Select Child</label>
                        <Dropdown
                            // id="childId"
                            name="childId"
                            value={selectedChild}
                            options={children.length ? children : []}
                            onChange={handleChange}
                            optionLabel="fullName"
                            optionValue="id"
                            placeholder="Select Child"
                            className='full-width'
                        />
                    </div>
                    <div className="p-field">
                        <label htmlFor="sessionsPerMonth">Sessions/Month (Max 22)</label>
                        <InputNumber
                            id="sessionsPerMonth"
                            name="sessionsPerMonth"
                            value={subscriptionData.sessionsPerMonth}
                            onValueChange={handleChange}
                            max={22}
                            className='full-width'
                        />
                    </div>
                    <div className='mt-2'>
                        <Button label="Submit" className='full-width btn-primary' icon="pi pi-check" onClick={() => handleSubscriptionSubmit()} />
                    </div>
                </div>
            </Dialog>
        </>
    );
};

export default ServicesList;
