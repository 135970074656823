// dateUtils.js
export const formatDate = (dateString) => {
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, "0"); // Pads single-digit days with a leading zero
  const month = date.toLocaleString("en-US", { month: "short" }); // Short month name (e.g., Aug)
  const year = date.getFullYear();

  return `${day}-${month}-${year}`;
};
export const capitalizeFirstLetter = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};
