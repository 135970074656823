import React, { useState } from 'react';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { InputTextarea } from 'primereact/inputtextarea';
import { InputNumber } from 'primereact/inputnumber';
import { Button } from 'primereact/button';


const SingleSubscription = ({ subscription, setSubscription, onSubmit, isEdit }) => {


  const handleChange = (e) => {
    const { name, value } = e.target;
    setSubscription((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };


  return (
    <div className="form-group">
      <div className="p-fluid">
        <div className="p-field">
          <label htmlFor="sessionsPerMonth">Sessions/Month</label>
          <InputNumber
            id="sessionsPerMonth"
            name="sessionsPerMonth"
            value={subscription.sessionsPerMonth}
            onValueChange={handleChange}
            max={22}
          />
        </div>

        <div className="p-field">
          <label htmlFor="amount">Subscription Fee</label>
          <InputNumber
            id="amount"
            name="amount"
            value={subscription.amount}
            onValueChange={handleChange}
            // mode="currency"
            // currency="PKR"
          />
        </div>
        
        <Button
          label={isEdit ? 'Update Subscription' : 'Add Subscription'}
          className='btn-primary mt-2'
          onClick={onSubmit}
        />
      </div>
    </div>
  );
};

export default SingleSubscription;
