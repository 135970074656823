import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { useAuth } from "../../contexts/AuthContext";
import {
  getSubscriptions,
  createSubscription,
  updateSubscription,
  deleteSubscription,
} from "../../api/subscriptionApi";
import {
  createPayment,
  isPaidAlreadyForCurrentMonth,
} from "../../api/paymentApi";
import { fetchUserPermissions } from "../../api/userApi";
import SingleSubscription from "./SingleSubscription";
import DataListing from "../Common/DataListing";
import { Dialog } from "primereact/dialog";
import { InputSwitch } from "primereact/inputswitch";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import { InputNumber } from "primereact/inputnumber";
import { Button } from "primereact/button";
import { hasPermission } from "../../utils/permissions";
import { formatDate } from "../../utils/dateutils";

const SubscriptionsList = () => {
  const [permissions, setPermissions] = useState([]);
  const { user } = useAuth();
  const [subscriptions, setSubscriptions] = useState([]);
  const [subscriptionData, setSubscriptionData] = useState({
    amount: 0.0,
    sessionsPerMonth: null,
    // months: null,
  }); //to be used for SingleSubscription (Add/Edit)

  const [paymentData, setPaymentData] = useState({
    amount: 0.0,
    dueDate: null,
    status: null,
    paidDate: null,
  }); //to be used for Payment Entry

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isPaymentModalVisible, setIsPaymentModalVisible] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [maxPaymentAmount, setMaxPaymentAmount] = useState(false);

  const renderStatusSwitch = (rowData) => {
    return (
      <InputSwitch
        checked={rowData.status}
        onChange={(e) => handleInlineEdit(rowData.id, { status: e.value })}
      />
    );
  };

  const renderDate = (fieldName) => {
    return (rowData) => {
      return <span>{formatDate(rowData[fieldName])}</span>;
    };
  };

  const rendersubscriptionType = (rowData) => {
    return <span>{rowData.serviceId ? "Service" : "Plan"}</span>;
  };

  const renderParentName = (rowData) => {
    return <span>{rowData.user.name}</span>;
  };
  const renderChildName = (rowData) => {
    console.log(rowData);
    return <span>{rowData.child?.fullName}</span>;
  };
  const [columns, setColumns] = useState([
    { field: "id", header: "Subscription Id" },
    { header: "Date", body: renderDate("createdAt") },
    { field: "userId", header: "Parent", body: renderParentName },
    { header: "Child", body: renderChildName },
    { header: "Subscription Type", body: rendersubscriptionType },
    { header: "Sessons/Month", field: "sessionsPerMonth" },
    { field: "amount", header: "Fee" },
    { header: "Status", body: renderStatusSwitch },
  ]); //to be used for DataListing

  useEffect(() => {
    fetchSubscriptions();
    const getPermissions = async () => {
      try {
        const userPermissions = await fetchUserPermissions(user.id);
        setPermissions(userPermissions);
      } catch (error) {
        console.error("Error fetching permissions:", error);
      }
    };
    getPermissions();
  }, []);

  const fetchSubscriptions = async () => {
    try {
      const response = await getSubscriptions();
      console.log("Subscriptions", response);
      setSubscriptions(response);
    } catch (error) {
      console.error("Failed to fetch subscriptions", error);
    }
  };

  const responseAlert = (response, autoClose) => {
    if (response.success) {
      toast.success(response.msg, { autoClose: autoClose });
    } else {
      toast.error(response.msg, { autoClose: autoClose });
    }
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setPaymentData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleInlineEdit = async (subscriptionId, subscriptionData) => {
    await updateSubscription(subscriptionId, subscriptionData);
    fetchSubscriptions();
  };

  const handleDelete = async (id) => {
    try {
      await deleteSubscription(id);
      fetchSubscriptions();
    } catch (error) {
      console.error("Failed to delete subscription", error);
    }
  };

  const handleEdit = (rowData) => {
    setIsEdit(true);
    console.log("subscription rowData", rowData);
    setSubscriptionData(rowData);
    setIsModalVisible(true);
  };

  const handleCreate = () => {
    setIsEdit(false);
    setSubscriptionData({
      name: "",
      subscriptionType: "",
      details: "",
    });
    setIsModalVisible(true);
  };

  const handlePaymentSubmit = async () => {
    const isPaidAlready = await isPaidAlreadyForCurrentMonth(paymentData);
    if (isPaidAlready.success) {
      toast.error("Payment done Already!", { autoClose: 300 });
      setIsPaymentModalVisible(false);
      return;
    }
    let response = await createPayment(paymentData);
    responseAlert(response);
    setIsPaymentModalVisible(false);
  };

  const handleSubmit = async () => {
    try {
      let response = {};
      if (isEdit) {
        response = await updateSubscription(
          subscriptionData.id,
          subscriptionData
        );
      } else {
        response = await createSubscription(subscriptionData);
      }
      // console.log(response.data);
      responseAlert(response, 300);
      fetchSubscriptions(); // Refresh the list after submission
      setIsModalVisible(false);
    } catch (error) {
      console.error("Error saving subscription", error);
    }
  };

  const handlePaymentEntry = async (rowData) => {
    const updatedpaymentData = {
      ...paymentData,
      subscriptionId: rowData.id,
      userId: rowData.userId,
    };
    setPaymentData(updatedpaymentData);
    setMaxPaymentAmount(rowData.amount);
    setIsPaymentModalVisible(true);
  };

  const paymentEntryAction = [
    {
      // icon: 'pi pi-user-edit',
      label: "Add Payment",
      className: "btn-outline-primary",
      handler: handlePaymentEntry,
      condition: (rowData) => {
        return hasPermission(permissions, "Payment", "create");
      },
    },
  ];

  return (
    <>
      <DataListing
        data={subscriptions}
        subject={"Subscription"}
        columns={columns}
        onCreate={null}
        onEdit={handleEdit}
        onDelete={handleDelete}
        extraActions={paymentEntryAction}
      />
      <Dialog
        header="Subscription Details"
        visible={isModalVisible}
        style={{ width: "30vw" }}
        onHide={() => setIsModalVisible(false)}
      >
        <SingleSubscription
          subscription={subscriptionData}
          setSubscription={setSubscriptionData}
          onSubmit={handleSubmit}
          isEdit={isEdit}
        />
      </Dialog>
      <Dialog
        header="Payment Details"
        visible={isPaymentModalVisible}
        style={{ width: "30vw" }}
        onHide={() => setIsPaymentModalVisible(false)}
      >
        <div className="form-group">
          <div className="p-field">
            <label htmlFor="amount">Amount</label>
            <InputNumber
              id="amount"
              name="amount"
              value={paymentData.amount}
              onValueChange={handleChange}
              className="full-width"
              max={maxPaymentAmount}
            />
          </div>
          <div className="p-field">
            <label htmlFor="dueDate">Due Date</label>
            <Calendar
              id="dueDate"
              name="dueDate"
              value={paymentData.dueDate}
              onChange={handleChange}
              dateFormat="yy-mm-dd"
              showIcon
              placeholder="Select Due Date"
              className="full-width"
              required
            />
          </div>
          <div className="p-field">
            <label htmlFor="status">Status</label>
            <Dropdown
              id="status"
              name="status"
              value={paymentData.status}
              options={[
                { label: "Pending", value: "pending" },
                { label: "Paid", value: "paid" },
                { label: "Overdue", value: "overdue" },
              ]}
              onChange={handleChange}
              placeholder="Select Status"
              className="full-width"
              required
            />
          </div>
          <div className="p-field">
            <label htmlFor="paidDate">Paid Date</label>
            <Calendar
              id="paidDate"
              name="paidDate"
              value={paymentData.paidDate}
              onChange={handleChange}
              dateFormat="yy-mm-dd"
              showIcon
              placeholder="Select Payment Date"
              className="full-width"
              required
            />
          </div>
        </div>
        <div className="mt-2">
          <Button
            label="Submit"
            className="full-width btn-primary"
            icon="pi pi-check"
            onClick={() => handlePaymentSubmit()}
          />
        </div>
      </Dialog>
    </>
  );
};

export default SubscriptionsList;
