import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify'
import SinglePlan from './SinglePlan';
import { getAllPlans, createPlan, updatePlan, deletePlan } from '../../api/planApi';
import { isPlanAlreadySubscribed, createSubscription } from '../../api/subscriptionApi';
import { getChildren } from '../../api/childApi';
import DataListing from '../Common/DataListing';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { useAuth } from '../../contexts/AuthContext';

const PlansList = () => {
    const { user } = useAuth();
    const [plans, setPlans] = useState([]);
    const [planData, setPlanData] = useState({
        name: '',
        details: '',
        onlineFee: null,
        onsiteFee: null,
    });  //to be used for SinglePlan (Add/Edit)
    const [subscriptionData, setSubscriptionData] = useState({
        planId: null,
        userId: user.id,
        sessionsPerMonth: 1
    });  //to be used for plan Subscription
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [isSubscriptionModalVisible, setIsSubscriptionModalVisible] = useState(false);
    const [children, setChildren] = useState([]);
    const [selectedChild, setSelectedChild] = useState();

    const renderPlanServices = (rowData) => {

        return (
            <div>
                {rowData.services.map(serviceObj => (
                    <div key={serviceObj.id} style={{
                        // display: 'inline-block', 
                        padding: '5px 10px',
                        margin: '5px',
                        backgroundColor: '#e0f7fa',
                        color: '#00796b',
                        borderRadius: '16px',
                        fontSize: '14px',
                    }}>
                        {serviceObj.name} - {serviceObj.daysPerMonth} days/month, {serviceObj.hoursPerDay} hours/day
                    </div>
                ))}
            </div>
        );
    };
    const [columns, setColumns] = useState([
        { field: 'name', header: 'Name' },
        { header: 'Services Included', body: renderPlanServices, style: { whiteSpace: 'pre-line' } },
        { field: 'details', header: 'Detail' }
    ])

    useEffect(() => {
        fetchPlans();
        fetchChildren();
    }, []);

    const fetchPlans = async () => {
        const plansData = await getAllPlans();
        setPlans(plansData);
    };
    const fetchChildren = async () => {
        try {
            const response = await getChildren();
            setChildren(response);
        } catch (error) {
            console.error('Failed to fetch services', error);
        }
    };
    const responseAlert = (response, autoClose) => {
        if (response.success) {
            toast.success(response.msg, { autoClose: autoClose })
        }
        else {
            toast.error(response.msg, { autoClose: autoClose })
        }

    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        console.log(name, value);
        setSubscriptionData((prevState) => ({
            ...prevState,
            [name]: value,
        }));
        if (name == 'childId') {
            setSelectedChild(value);
        }
    };

    const handleCreate = () => {
        setIsEdit(false);
        setPlanData({
            name: '',
            details: ''
        });
        setIsModalVisible(true);
    };

    const handleEdit = (rowData) => {
        setIsEdit(true);
        setPlanData(rowData);
        setIsModalVisible(true);
    };

    const handleDelete = async (id) => {
        try {
            await deletePlan(id);
            fetchPlans();
        } catch (error) {
            console.error('Failed to delete plan', error);
        }
    };

    const handleSubmit = async () => {
        try {
            let response = {}
            if (isEdit) {
                response = await updatePlan(planData.id, planData);
            } else {
                console.log("planData", planData);
                response = await createPlan(planData);
            }
            console.log(response);
            responseAlert(response, 300);
            fetchPlans(); // Refresh the list after submission
            setIsModalVisible(false);
        } catch (error) {
            console.error('Error saving plan', error);
        }
    };

    const handleSubscriptionSubmit = async (data = subscriptionData) => {
        // let submitData = data ? data:subscriptionData;
        const isSubscribed = await isPlanAlreadySubscribed(subscriptionData);
        if (isSubscribed.success) {
            toast.error("Plan Already Subscribed!", { autoClose: 300 })
            return;
        }
        let response = await createSubscription(data);
        setIsSubscriptionModalVisible(false);
        responseAlert(response);
    };

    const handleSubscription = async (rowData) => {

        const updatedSubscriptionData = {
            ...subscriptionData,
            planId: rowData.id,
            userId: user.id,
        };
        setSubscriptionData(updatedSubscriptionData);
        setIsSubscriptionModalVisible(true);
       
        // handleSubscriptionSubmit(updatedSubscriptionData)
    };

    const serviceSubscriptionAction = [
        {
            // icon: 'pi pi-user-edit',
            label: 'Subscribe',
            className: 'btn-outline-primary',
            handler: handleSubscription,
            condition: (rowData) => true
        }
    ];

    return (
        <>
            <DataListing
                data={plans}
                subject={"Plan"}
                columns={columns}
                onCreate={handleCreate}
                onEdit={handleEdit}
                onDelete={handleDelete}
                extraActions={serviceSubscriptionAction} />
            <Dialog
                header="Plan Details"
                visible={isModalVisible}
                style={{ width: '30vw' }}
                onHide={() => setIsModalVisible(false)}>
                <SinglePlan
                    plan={planData}
                    setPlan={setPlanData}
                    onSubmit={handleSubmit}
                    isEdit={isEdit}
                />
            </Dialog>
            <Dialog
                header="Subscription Details"
                visible={isSubscriptionModalVisible}
                style={{ width: '20vw' }}
                onHide={() => setIsSubscriptionModalVisible(false)}>

                <div className="form-group">
                    <div className="p-field">
                        <label htmlFor="childId">Select Child</label>
                        <Dropdown
                            // id="childId"
                            name="childId"
                            value={selectedChild}
                            options={children.length ? children : []}
                            onChange={handleChange}
                            optionLabel="fullName"
                            optionValue="id"
                            placeholder="Select Child"
                            className='full-width'
                        />
                    </div>
                    
                    <div className='mt-2'>
                        <Button label="Submit" className='full-width btn-primary' icon="pi pi-check" onClick={() => handleSubscriptionSubmit()} />
                    </div>
                </div>
            </Dialog>
        </>
    );
};

export default PlansList;
