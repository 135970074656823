import React, { useState, useEffect } from 'react';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { getDeviceBrands } from '../../api/deviceBrandApi';


const SingleDeviceModel = ({ deviceModel, setDeviceModel, onSubmit, isEdit }) => {
  
  const [deviceBrands, setDeviceBrands] = useState([]);
  useEffect(() => {
    fetchDeviceBrands();
  }, []);

  const fetchDeviceBrands = async () => {
    try {
      const response = await getDeviceBrands();
      setDeviceBrands(response);
    } catch (error) {
      console.error('Failed to fetch device brands', error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setDeviceModel((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    console.log("deviceModel",deviceModel);
  };


  return (
    <div className="form-group">
      <div className="p-fluid">

        <div className="p-field">
          <label htmlFor="name">Device Model</label>
          <InputText
            id="name"
            name="name"
            value={deviceModel.name}
            onChange={handleChange}
          />
        </div>
        <div className="p-field">
          <label htmlFor="DeviceBrandId">Device Brand</label>
          <Dropdown
            id="DeviceBrandId"
            name="DeviceBrandId"
            value={deviceModel.DeviceBrandId}
            options={deviceBrands}
            onChange={handleChange}
            optionLabel='name'
            optionValue='id'
            placeholder="Select Device Brand"
            required
          />
        </div>

        <Button
          label={isEdit ? 'Update Device Model' : 'Add Device Model'}
          className='btn-primary mt-2'
          onClick={onSubmit}
        />
      </div>
    </div>
  );
};

export default SingleDeviceModel;
