import axios from 'axios';

const API_URL = '/api/child';

export const createChild = async (childData) => {
    const response = await axios.post(API_URL, childData);
    return response.data;
};

export const getChildren = async () => {
    const response = await axios.get(API_URL);
    return response.data;
};

export const updateChild = async (id, childData) => {
    const response = await axios.put(`${API_URL}/${id}`, childData);
    return response.data;
};

export const deleteChild = async (id) => {
    const response = await axios.delete(`${API_URL}/${id}`);
    return response.data;
};
