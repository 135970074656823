import axios from "axios";

const API_URL = "/api/goals";

export const createGoal = async (goal, goalDetails) => {
  const response = await axios.post(API_URL, { goal, goalDetails });
  return response.data;
};

export const getAllGoals = async () => {
  const response = await axios.get(API_URL);
  return response.data;
};
export const getChildGoals = async (childId) => {
  const response = await axios.get(`${API_URL}/${childId}`);
  return response.data;
};
export const deleteGoal = async (id) => {
  const response = await axios.delete(`${API_URL}/${id}`);
  return response.data;
};

export const updateGoal = async (id, goal, goalDetails) => {
  const response = await axios.put(`${API_URL}/${id}`, { goal, goalDetails });
  return response.data;
};
